.wrapper {
  width: 100%;
  padding-bottom: var(--spacing-16);
  padding-top: var(--spacing-16);

  & :global(.reviews-scrollbox) {
    @media (--tablet-l) {
      width: 100%;
      overflow-y: hidden;
    }
  }
  & :global(.reviews-nav) {
    display: none;
    @media (--tablet-l) {
      display: flex;
      padding-top: var(--spacing-4);
      top: auto;
    }
  }

  &.hasBorderBottom {
    border-bottom: 1px solid var(--color-tarmac-grey-300);
  }
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 0;

  @media (--tablet) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: var(--spacing-6);
  }

  & h2 {
    margin: 0;
  }
}

.cta {
  padding-top: 0;
  width: 100%;
  color: var(--neutral-700);
  justify-content: start;
  @media (--tablet) {
    justify-self: flex-end;
    width: auto;
    padding-top: var(--spacing-6);
  }
}

.reel {
  display: flex;
  list-style: none;
  margin: 0 auto;
  padding: 0;
  gap: var(--spacing-4);

  @media (--tablet-l) {
    width: 100%;
  }
}

.reviews {
  background: var(--color-championship-white);
  min-width: 300px;

  & article {
    height: 100%;
  }

  @media (--tablet) {
    flex-grow: 1;

    & > a {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
}
