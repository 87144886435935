/*
* Vars
*/

.svgIcon {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  flex-shrink: 0;
  color: inherit;
}

.primary {
  color: var(--icon-primary-color);
}
.secondary {
  color: var(--icon-secondary-color);
}
.tertiary {
  color: var(--icon-tertiary-color);
}
