.wrapper {
  padding: var(--spacing-10);

  & h2 {
    margin-top: 0;
  }

  & p {
    margin-bottom: var(--spacing-4);
  }
}
