.reviewWrapper {
  padding: 0 0 var(--spacing-10) 0;

  @media (--tablet-l) {
    padding: var(--spacing-6) 0 var(--spacing-6);
  }
}

.reviewsRating {
  display: flex;
  gap: var(--spacing-2);
  align-items: center;

  & .ratingDivider {
    border-left: 1px solid var(--neutral-300-disabled, #b5b5b5);
    padding-left: var(--spacing-2);
  }
}

.desktopViewRating {
  padding-bottom: var(--spacing-5);
  justify-content: center;

  @media (--tablet) {
    justify-content: flex-start;
  }
}

.mobileViewRating {
  display: flex;
  align-items: center;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobileViewTitle {
  display: flex;
  justify-content: center;
}

.prosConsWrapper {
  border: 1px solid var(--neutral-300-disabled, #b5b5b5);
  padding: var(--spacing-5);
}

.link {
  color: inherit;
}

.linkWithRating {
  padding-bottom: var(--spacing-5);
}

.mobileViewLink {
  display: flex;
  justify-content: center;
  padding: var(--spacing-5) 0;
}
