.tooltipWrapper {
  display: inline-flex;
}
.expandable {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  background-color: white;
  width: 100%;
  z-index: 6;
  padding: var(--spacing-4);
  transition: all 0.3s, transform 0.3s;
  border: 1px solid var(--color-tarmac-grey-100);
  bottom: -100%;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: default;

  &.open {
    height: 100%;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
  }
  & .fullLegalText {
    font-size: var(--spacing-3);
    font-weight: 400;
    overflow-y: scroll;
  }
  & .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  & .closeButton {
    cursor: pointer;
    & svg {
      fill: var(--color-neutral-700);
    }
  }
}

& .toggle {
  cursor: pointer;
  & svg {
    transform: translateY(var(--spacing-2));
  }
}
