/* cyrillic-ext */
@font-face {
  font-family: '__Source_Code_Pro_02692e';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/2053df8159b25386-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Code_Pro_02692e';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/9d9b9cae20d87d18-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Code_Pro_02692e';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/891487401855818d-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Code_Pro_02692e';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/64ea2a5aaa4dedd3-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Code_Pro_02692e';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/b63e4df112f8dce1-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Code_Pro_02692e';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/1a142ec2652f2d06-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Code_Pro_02692e';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/dba81c1208da12ee-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Code_Pro_02692e';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/2053df8159b25386-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Code_Pro_02692e';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/9d9b9cae20d87d18-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Code_Pro_02692e';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/891487401855818d-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Code_Pro_02692e';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/64ea2a5aaa4dedd3-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Code_Pro_02692e';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/b63e4df112f8dce1-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Code_Pro_02692e';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/1a142ec2652f2d06-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Code_Pro_02692e';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/dba81c1208da12ee-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Code_Pro_02692e';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/2053df8159b25386-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Code_Pro_02692e';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/9d9b9cae20d87d18-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Code_Pro_02692e';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/891487401855818d-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Code_Pro_02692e';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/64ea2a5aaa4dedd3-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Code_Pro_02692e';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/b63e4df112f8dce1-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Code_Pro_02692e';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/1a142ec2652f2d06-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Code_Pro_02692e';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/dba81c1208da12ee-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Source_Code_Pro_Fallback_02692e';src: local("Arial");ascent-override: 73.11%;descent-override: 20.28%;line-gap-override: 0.00%;size-adjust: 134.59%
}.__className_02692e {font-family: '__Source_Code_Pro_02692e', '__Source_Code_Pro_Fallback_02692e';font-style: normal
}.__variable_02692e {--source-code-pro: '__Source_Code_Pro_02692e', '__Source_Code_Pro_Fallback_02692e'
}

/*
* Vars
*/

.Default_svgIcon___F9Lm {
  -webkit-user-select: none;
          user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  flex-shrink: 0;
  color: inherit;
}

.Default_primary__QG1iK {
  color: var(--icon-primary-color);
}

.Default_secondary__Z8__I {
  color: var(--icon-secondary-color);
}

.Default_tertiary__hSiBQ {
  color: var(--icon-tertiary-color);
}

:root {
  /* heycar mint color */
  --color-heycar-mint-50: #e6faf6;
  --color-heycar-mint-100: #ccf5ed;
  --color-heycar-mint-200: #99ebdb;
  --color-heycar-mint-300: #66e2c9;
  --color-heycar-mint-400: #33d8b7;
  --color-heycar-mint-500: #00cea5;
  --color-heycar-mint-600: #00a584;
  --color-heycar-mint-700: #007c63;
  --color-heycar-mint-800: #005242;
  --color-heycar-mint-900: #002921;

  /* mica blue color */
  --color-mica-blue-50: #f1f4fa;
  --color-mica-blue-100: #dee6f2;
  --color-mica-blue-200: #a2b7da;
  --color-mica-blue-300: #7394c8;
  --color-mica-blue-400: #4570b5;
  --color-mica-blue-500: #164ca3;
  --color-mica-blue-600: #123d82;
  --color-mica-blue-700: #052962;
  --color-mica-blue-800: #091e41;
  --color-mica-blue-900: #040f21;

  /* sunbeam blue color */
  --color-sunbeam-blue-50: #f0f9ff;
  --color-sunbeam-blue-100: #e8f6ff;
  --color-sunbeam-blue-200: #e1f3ff;
  --color-sunbeam-blue-300: #d9f0ff;
  --color-sunbeam-blue-400: #cdebff;
  --color-sunbeam-blue-500: #c0e6ff;
  --color-sunbeam-blue-600: #9bd1f4;
  --color-sunbeam-blue-700: #73b6e1;
  --color-sunbeam-blue-800: #4499cf;
  --color-sunbeam-blue-900: #2b6f99;

  /* mustang yellow color */
  --color-mustang-yellow-50: #fef8eb;
  --color-mustang-yellow-100: #fef0d8;
  --color-mustang-yellow-200: #fde1b0;
  --color-mustang-yellow-300: #fbd289;
  --color-mustang-yellow-400: #fac361;
  --color-mustang-yellow-500: #f9b43a;
  --color-mustang-yellow-600: #e29a1a;
  --color-mustang-yellow-700: #c9850c;
  --color-mustang-yellow-800: #ae7000;
  --color-mustang-yellow-900: #895800;

  /* old ferrari red color */
  --color-old-ferrari-red-50: #fff1ef;
  --color-old-ferrari-red-100: #ffe3df;
  --color-old-ferrari-red-200: #ffc8be;
  --color-old-ferrari-red-300: #ffac9e;
  --color-old-ferrari-red-400: #ff917d;
  --color-old-ferrari-red-500: #ff755d;
  --color-old-ferrari-red-600: #f46147;
  --color-old-ferrari-red-700: #e24529;
  --color-old-ferrari-red-800: #d73013;
  --color-old-ferrari-red-900: #c7270a;

  /* -- fantasy name of "green" color to be defined -- */
  --color-green-50: #e9f3ed;
  --color-green-100: #d2e8db;
  --color-green-200: #a5d1b8;
  --color-green-300: #79b994;
  --color-green-400: #4ca271;
  --color-green-500: #1f8b4d;
  --color-green-600: #196f3e;
  --color-green-700: #13532e;
  --color-green-800: #0c381f;
  --color-green-900: #061c0f;

  /* -- fantasy name of "whatsapp" color to be defined -- */
  --color-whatsapp-500: #59ce72;
  --color-whatsapp-600: #33a84c;
  --color-whatsapp-700: #148a2d;

  /* -- fantasy name of "red" color to be defined -- */
  --color-red-50: #fdeeee;
  --color-red-100: #fbdddd;
  --color-red-200: #f7bcbc;
  --color-red-300: #f39a9a;
  --color-red-400: #ef7979;
  --color-red-500: #eb5757;
  --color-red-600: #d73535;
  --color-red-700: #c61a1a;
  --color-red-800: #b90606;
  --color-red-900: #a00;

  /* tarmac grey color */
  --color-tarmac-grey-50: #f3f3f3;
  --color-tarmac-grey-100: #e6e6e6;
  --color-tarmac-grey-200: #cdcdcd;
  --color-tarmac-grey-300: #b5b5b5;
  --color-tarmac-grey-400: #9c9c9c;
  --color-tarmac-grey-500: #838383;
  --color-tarmac-grey-600: #595959;
  --color-tarmac-grey-700: #303030;
  --color-tarmac-grey-800: #262626;
  --color-tarmac-grey-900: #1d1d1d;

  /* brand colors */
  --color-heycar-mint: var(--color-heycar-mint-500);
  --color-mica-blue: var(--color-mica-blue-700);
  --color-sunbeam-blue: var(--color-sunbeam-blue-500);
  --color-championship-white: #fff;
  --color-overlay: rgba(4, 15, 33, 0.6);

  /* secondary colors */
  --warning-100: #fef3df;
  --warning-200: #fde7c0;
  --warning-400: #fbcf81;
}
:root {
  --spacing-1: 4px;
  --spacing-2: 8px;
  --spacing-3: 12px;
  --spacing-4: 16px;
  --spacing-5: 20px;
  --spacing-6: 24px;
  --spacing-7: 28px;
  --spacing-8: 32px;
  --spacing-9: 36px;
  --spacing-10: 40px;
  --spacing-11: 44px;
  --spacing-12: 48px;
  --spacing-14: 56px;
  --spacing-16: 64px;
  --spacing-17: 68px;
  --spacing-18: 72px;
  --spacing-20: 80px;
}
:root {
  --shadow-none: 'none';
  --shadow-xs: 0 2px 4px rgba(38, 38, 38, 0.06);
  --shadow-s: 0 2px 8px rgba(38, 38, 38, 0.08);
  --shadow-m: 0 4px 12px rgba(38, 38, 38, 0.08);
  --shadow-xl: 0 6px 24px rgba(38, 38, 38, 0.1);
  --shadow-xxl: 2px 8px 48px rgba(38, 38, 38, 0.2);
}
:root {
  /* Font families */
  --font-family-system: Objektiv, sans-serif, 'Helvetica Neue', Helvetica, Arial;

  /* Font weights */
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 600;
  --font-weight-xbold: 800;
}
/*
  Heading
*/
.typography-display {
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -2px;
  font-family: var(--font-family-system);
  font-weight: var(--font-weight-xbold);
}
@media (min-width: 768px) {
.typography-display {
    font-size: 60px;
    line-height: 68px;
    letter-spacing: -2.5px;
}
  }
.typography-display-2 {
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -2px;
  font-family: var(--font-family-system);
  font-weight: var(--font-weight-bold);
}
@media (min-width: 768px) {
.typography-display-2 {
    font-size: 60px;
    line-height: 68px;
    letter-spacing: -2.5px;
}
  }
.typography-heading-1 {
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -2px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}
.typography-heading-1_mobile {
  letter-spacing: -0.5px;
  font-size: 32px;
  line-height: 40px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}
.typography-heading-2 {
  font-size: 32px;
  letter-spacing: -0.5px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}
.typography-heading-2_mobile {
  font-size: 28px;
  letter-spacing: -0.5px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}
.typography-heading-3 {
  font-size: 28px;
  letter-spacing: -0.5px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}
.typography-heading-3_mobile {
  font-size: 24px;
  letter-spacing: -0.5px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}
.typography-heading-4 {
  font-size: 24px;
  letter-spacing: -0.5px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}
.typography-heading-4_mobile {
  letter-spacing: 0;
  font-size: 20px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}
.typography-heading-5 {
  font-size: 20px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}
.typography-heading-5_mobile {
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}
.typography-heading-6 {
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}
/*
  Sub-Heading
*/
.typography-subheading-1 {
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}
.typography-subheading-2 {
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
}
.typography-subheading-3 {
  font-size: 14px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
}
.typography-subheading-4 {
  font-size: 16px;
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family-system);
}
/*
  Body
*/
.typography-body-1 {
  font-size: 16px;
  line-height: 26px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
}
.typography-body-2 {
  font-size: 14px;
  line-height: 22px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
}
.typography-body-3 {
  font-size: 16px;
  line-height: 26px;
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family-system);
}
.typography-body-4 {
  font-size: 14px;
  line-height: 22px;
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family-system);
}
/*
  Caption
*/
.typography-caption-1 {
  font-size: 12px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
}
.typography-caption-2 {
  font-size: 12px;
  line-height: 18px;
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family-system);
}
.typography-caption-3 {
  font-size: 10px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}
.typography-caption-4 {
  font-size: 10px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
}
.typography-caption-5 {
  font-size: 10px;
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family-system);
}
/*
  Overline
*/
.typography-overline-1 {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
}
.typography-overline-2 {
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
}
/*
  Button
*/
.typography-button-1 {
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.2px;
  font-family: var(--font-family-system);
}
.typography-button-2 {
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.2px;
  font-family: var(--font-family-system);
}
.typography-button-3 {
  font-size: 12px;
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.2px;
  font-family: var(--font-family-system);
}
/* If this is ever changed please update breakpoints.json as well */
/* Mobile */
/* Tablet */
/* Desktop */
/* Sizes */
:root {
  --button-small-height: var(--size-small-height);
  --button-large-height: var(--size-large-height);
}
/* Variants */
:root {
  /* disabled */
  --button-disabled-color: var(--color-neutral-300);
  --button-disabled-bg-color: var(--color-neutral-50);

  /* primary */
  --button-primary-color: #fff;
  --button-primary-base-bg-color: var(--color-primary-500);
  --button-primary-hover-bg-color: var(--color-primary-600);
  --button-primary-active-bg-color: var(--color-primary-700);

  /* tertiary */
  --button-tertiary-color: var(--color-neutral-700);
  --button-tertiary-base-bg-color: var(--color-tertiary-500);
  --button-tertiary-hover-bg-color: var(--color-tertiary-600);
  --button-tertiary-active-bg-color: var(--color-tertiary-700);

  /* whatsapp */
  --button-whatsapp-color: #fff;
  --button-whatsapp-base-bg-color: var(--color-whatsapp-500);
  --button-whatsapp-hover-bg-color: var(--color-whatsapp-600);
  --button-whatsapp-active-bg-color: var(--color-whatsapp-700);
}
/* Chip */
:root {
  /* border */
  --chip-border-color: var(--color-neutral-300);

  /* default */
  --chip-default-background: var(--color-neutral-50);

  /* hover */
  --chip-hover-background: var(--color-neutral-100);

  /* pressed */
  --chip-pressed-background: var(--color-neutral-200);

  /* disable */
  --chip-disable-background: var(--color-neutral-50);

  /* text */
  --chip-text-color: var(--color-tarmac-grey-700);

  /* text disabled */
  --chip-text-disabled-color: var(--color-tarmac-grey-400);

  /* selected color */
  --chip-selected-color: var(--color-mica-blue-500);

  /* border */
  --chip-selected-border-color: var(--color-mica-blue-500);
}
/* Dropdown */
:root {
  --option-background-color: var(--color-tarmac-grey-50);
  --option-text-color: var(--color-tarmac-grey-700);
  --container-border-color: var(--color-neutral-500);
  --container-focus-border-color: var(--color-mica-blue-500);
  --container-disabled-color: var(--color-neutral-300);
  --options-border-color: var(--color-neutral-700);
  --options-background-color: var(--color-championship-white);
}
/* FormControl */
:root {
  /* Color - base */
  --form-control-base-border-color: var(--color-neutral-500);
  --form-control-hover-border-color: var(--color-neutral-600);
  --form-control-focused-border-color: var(--color-primary-500);
  --form-control-filled-border-color: var(--color-neutral-500);
  --form-control-label-color: var(--color-neutral-700);
  --form-control-hint-color: var(--color-neutral-700);
  --form-control-icon-color: var(--color-neutral-700);

  /* Color - error */
  --form-control-error-color: var(--color-error-700);
  --form-control-error-label-color: var(--color-error-700);

  /* Color - disabled */
  --form-control-disabled-bg-color: var(--color-neutral-300);
  --form-control-disabled-color: var(--color-neutral-300);

  /* Sizes */
  --form-control-min-height: 48px;
  --from-control-legend-height: 11px;
  --form-control-input-padding: 0;
  --form-control-hint-fint-size: 12px;
}
/* Col */
:root {
  --grid-col-width: calc(100% / 12);
  margin: 0 auto;
}
/* SvgIcon */
:root {
  /* primary */
  --icon-primary-color: var(--color-primary-500);

  /* secondary */
  --icon-secondary-color: var(--color-secondary-500);

  /* tertiary */
  --icon-tertiary-color: var(--color-tertiary-500);
}
/* Icons */
:root {
  /* primary */
  --icon-primary-color: var(--color-primary-500);

  /* secondary */
  --icon-secondary-color: var(--color-secondary-500);

  /* tertiary */
  --icon-tertiary-color: var(--color-tertiary-500);
}
/* Input */
:root {
  /* Color */
  --input-color: var(--color-neutral-900);
  --input-caret-color: var(--color-neutral-900);
  --input-placeholder-color: var(--color-neutral-900);
  --input-disabled-color: var(--color-neutral-300);
}
/* Logo */
:root {
  /* primary */
  --fill-primary-color: var(--color-championship-white);
  --fill-primary-undermark-color: var(--color-heycar-mint-500);

  /* secondary */
  --fill-secondary-color: var(--color-mica-blue);
  --fill-secondary-undermark-color: var(--color-heycar-mint-500);

  /* tertiary */
  --fill-tertiary-color: var(--color-mica-blue);
  --fill-tertiary-undermark-color: var(--color-championship-white);
}
/* Header */
:root {
  /* background color */
  --header-nav-background-color: var(--color-primary-700);
  /* font color */
  --header-nav-item-color: var(--color-championship-white);
  --header-nav-item-hover-bg: var(--color-primary-800);
  --header-nav-item-active-bg: var(--color-primary-900);
  --header-nav-counter-bg: var(--color-secondary-500);
  --header-nav-fav-icon-bg: var(--color-secondary-100);
}
/* Pill */
:root {
  /* background color */
  --pill-background-color: var(--color-tarmac-grey-50);

  /* text color */
  --pill-text-color: var(--color-tarmac-grey-700);
}
/* Slider */
:root {
  --slider-thumb-size: 22px;
  --slider-halo-size: 40px;
  --slider-focus-visible: calc(var(--slider-thumb-size) * 1.1818181818181819);
  --slider-thumb-left-offset: -2px;
}
/* SliderWithHistogram */
:root {
  --histogram-height: 64px;
  --slider-height: 22px;
}
/* Switch */
:root {
  --switch-checked-color: var(--color-neutral-700);
  --switch-unchecked-color: var(--color-neutral-500);
  --switch-unchecked-hover-color: var(--color-neutral-700);
  --switch-unchecked-pressed-color: var(--color-neutral-700);
  --switch-disabled-color: var(--color-neutral-300);
  --switch-thumb-color: var(--color-championship-white);
  --switch-thumb-disabled-color: var(--color-neutral-200);
}
/* Textarea */
:root {
  /* Color */
  --textarea-color: var(--color-neutral-700);
  --textarea-caret-color: var(--color-neutral-700);
  --textarea-placeholder-color: var(--color-neutral-700);
  --textarea-disabled-color: var(--color-neutral-300);

  /* Sizes */
  --form-textarea-min-height: 80px;
}
/* Default theme (light) */
:root {
  /*
    Colors
  */
  --color-primary-50: var(--color-mica-blue-50);
  --color-primary-100: var(--color-mica-blue-100);
  --color-primary-200: var(--color-mica-blue-200);
  --color-primary-300: var(--color-mica-blue-300);
  --color-primary-400: var(--color-mica-blue-400);
  --color-primary-500: var(--color-mica-blue-500);
  --color-primary-600: var(--color-mica-blue-600);
  --color-primary-700: var(--color-mica-blue-700);
  --color-primary-800: var(--color-mica-blue-800);
  --color-primary-900: var(--color-mica-blue-900);
  --color-secondary-50: var(--color-heycar-mint-50);
  --color-secondary-100: var(--color-heycar-mint-100);
  --color-secondary-200: var(--color-heycar-mint-200);
  --color-secondary-300: var(--color-heycar-mint-300);
  --color-secondary-400: var(--color-heycar-mint-400);
  --color-secondary-500: var(--color-heycar-mint-500);
  --color-secondary-600: var(--color-heycar-mint-600);
  --color-secondary-700: var(--color-heycar-mint-700);
  --color-secondary-800: var(--color-heycar-mint-800);
  --color-secondary-900: var(--color-heycar-mint-900);
  --color-tertiary-50: var(--color-sunbeam-blue-50);
  --color-tertiary-100: var(--color-sunbeam-blue-100);
  --color-tertiary-200: var(--color-sunbeam-blue-200);
  --color-tertiary-300: var(--color-sunbeam-blue-300);
  --color-tertiary-400: var(--color-sunbeam-blue-400);
  --color-tertiary-500: var(--color-sunbeam-blue-500);
  --color-tertiary-600: var(--color-sunbeam-blue-600);
  --color-tertiary-700: var(--color-sunbeam-blue-700);
  --color-tertiary-800: var(--color-sunbeam-blue-800);
  --color-tertiary-900: var(--color-sunbeam-blue-900);
  --color-warning-50: var(--color-mustang-yellow-50);
  --color-warning-100: var(--color-mustang-yellow-100);
  --color-warning-200: var(--color-mustang-yellow-200);
  --color-warning-300: var(--color-mustang-yellow-300);
  --color-warning-400: var(--color-mustang-yellow-400);
  --color-warning-500: var(--color-mustang-yellow-500);
  --color-warning-600: var(--color-mustang-yellow-600);
  --color-warning-700: var(--color-mustang-yellow-700);
  --color-warning-800: var(--color-mustang-yellow-800);
  --color-warning-900: var(--color-mustang-yellow-900);
  --color-highlight-50: var(--color-old-ferrari-red-50);
  --color-highlight-100: var(--color-old-ferrari-red-100);
  --color-highlight-200: var(--color-old-ferrari-red-200);
  --color-highlight-300: var(--color-old-ferrari-red-300);
  --color-highlight-400: var(--color-old-ferrari-red-400);
  --color-highlight-500: var(--color-old-ferrari-red-500);
  --color-highlight-600: var(--color-old-ferrari-red-600);
  --color-highlight-700: var(--color-old-ferrari-red-700);
  --color-highlight-800: var(--color-old-ferrari-red-800);
  --color-highlight-900: var(--color-old-ferrari-red-900);
  --color-success-50: var(--color-green-50);
  --color-success-100: var(--color-green-100);
  --color-success-200: var(--color-green-200);
  --color-success-300: var(--color-green-300);
  --color-success-400: var(--color-green-400);
  --color-success-500: var(--color-green-500);
  --color-success-600: var(--color-green-600);
  --color-success-700: var(--color-green-700);
  --color-success-800: var(--color-green-800);
  --color-success-900: var(--color-green-900);
  --color-error-50: var(--color-red-50);
  --color-error-100: var(--color-red-100);
  --color-error-200: var(--color-red-200);
  --color-error-300: var(--color-red-300);
  --color-error-400: var(--color-red-400);
  --color-error-500: var(--color-red-500);
  --color-error-600: var(--color-red-600);
  --color-error-700: var(--color-red-700);
  --color-error-800: var(--color-red-800);
  --color-error-900: var(--color-red-900);
  --color-neutral-50: var(--color-tarmac-grey-50);
  --color-neutral-100: var(--color-tarmac-grey-100);
  --color-neutral-200: var(--color-tarmac-grey-200);
  --color-neutral-300: var(--color-tarmac-grey-300);
  --color-neutral-400: var(--color-tarmac-grey-400);
  --color-neutral-500: var(--color-tarmac-grey-500);
  --color-neutral-600: var(--color-tarmac-grey-600);
  --color-neutral-700: var(--color-tarmac-grey-700);
  --color-neutral-800: var(--color-tarmac-grey-800);
  --color-neutral-900: var(--color-tarmac-grey-900);

  /*
    Sizes
  */
  --size-small-height: var(--spacing-8);
  --size-middle-height: var(--spacing-10);
  --size-large-height: var(--spacing-12);

  /*
    Border radius
  */

  --border-radius: 0;
}
html {
  font-size: 16px;
}
body {
  color: var(--color-neutral-700);
}

.LoadingBackground_loadingBackgroundWrapper__zEc91 {
  height: 100%;
  position: relative;
  width: 100%;
  display: flex;
}

.LoadingBackground_loadingBackground__UZGAi {
  flex: 0 0 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-user-select: none;
          user-select: none;

  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: LoadingBackground_placeHolderShimmer__U0PfD;
  animation-timing-function: linear;
  background-color: var(--color-neutral-50);
  background: linear-gradient(
    to right,
    var(--color-neutral-100) 8%,
    var(--color-neutral-300) 18%,
    var(--color-neutral-100) 33%
  );
  background-size: 800px 104px;

  opacity: 1;
}

.LoadingBackground_loadingBackground__UZGAi[data-is-active='true'] {
    opacity: 1;
  }

@keyframes LoadingBackground_placeHolderShimmer__U0PfD {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}

.StatusDisplay_statusDisplayWrapper__a1z9j {
  width: 100%;
}

@media (min-width: 1024px) {

.StatusDisplay_statusDisplayWrapper__a1z9j {
    width: 800px;
}
  }

.StatusDisplay_statusDisplayBodyWrapper__8EThr {
  display: flex;
  flex-direction: column;
  flex: 1 0;
  justify-content: center;
}

@media (min-width: 1024px) {

.StatusDisplay_statusDisplayBodyWrapper__8EThr {
    display: block;
}
  }

.StatusDisplay_returnActionCta__s_L0H,
.StatusDisplay_resultDescription__i1Gb_ {
  text-align: center;
}

.StatusDisplay_resultDescription__i1Gb_,
.StatusDisplay_resultHeader__cuaDr {
  padding-top: var(--spacing-3);
}

.StatusDisplay_resultDescription__i1Gb_.StatusDisplay_resultDescription__i1Gb_, .StatusDisplay_resultDescription__i1Gb_.StatusDisplay_resultHeader__cuaDr, .StatusDisplay_resultHeader__cuaDr.StatusDisplay_resultDescription__i1Gb_, .StatusDisplay_resultHeader__cuaDr.StatusDisplay_resultHeader__cuaDr {
    margin: 0;
  }

.StatusDisplay_resultMessageWrapper__fV1uX {
  display: flex;
  flex-direction: column;
  padding-top: var(--spacing-8);
  place-items: center;
}

.PostSignup_wrapper__ao6F5 {
  width: 100%;
  display: flex;
  gap: var(--spacing-6);
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-6);
  background-color: var(--color-primary-50);
  margin-top: var(--spacing-8);
  margin-bottom: var(--spacing-8);
}

.PostSignup_wrapper__ao6F5 p {
    margin: 0;
    padding: 0;
  }

.PostSignup_wrapper__ao6F5 button {
    width: 100%;
  }

@media (min-width: 768px) {

.PostSignup_wrapper__ao6F5 button {
      width: 250px;
  }
    }

.PostSignup_avatar__fYlJC {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.PostSignup_avatar__fYlJC img {
    width: 50px;
  }

.PostSignup_heading__pXtMV {
  text-align: center;
}

.PostSignup_features__jwOhk {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-3) var(--spacing-10);
  justify-content: center;
  width: 100%;
  max-width: 960px;
}

@media (min-width: 768px) {

.PostSignup_features__jwOhk {
    flex-direction: row;
    align-items: center;
}
  }

.PostSignup_feature___TpgB {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  gap: var(--spacing-3);
}

.ReturnAction_preApprovalCard__kOUR8 {
  margin-top: var(--spacing-14);
}
.ReturnAction_preApprovalCard__kOUR8[data-variant='info'] {
    background-color: var(--color-mica-blue-50);
    color: var(--color-info-700);
    padding: 0 0 var(--spacing-4) var(--spacing-4);
  }
.ReturnAction_preApprovalCard__kOUR8[data-variant='info'] .ReturnAction_status__ajuXb {
      display: flex;
      flex-direction: column;
      text-align: left;
    }
.ReturnAction_preApprovalCard__kOUR8[data-variant='info'] .ReturnAction_status__ajuXb p {
        margin: 0;
      }
.ReturnAction_preApprovalCard__kOUR8[data-variant='info'] .ReturnAction_status__ajuXb p:first-child {
        font-size: 14px;
      }
.ReturnAction_preApprovalCard__kOUR8[data-variant='info'] .ReturnAction_status__ajuXb p:last-child {
        font-size: 14px;
      }
.ReturnAction_preApprovalCard__kOUR8 .ReturnAction_buttonGroup__4uZ7I {
    display: grid;
    grid-template-columns: 1fr 1fr;

    grid-gap: var(--spacing-3);

    gap: var(--spacing-3);
    margin: var(--spacing-6) var(--spacing-6) var(--spacing-2) 0;
  }
@media (max-width: 767px) {
.ReturnAction_preApprovalCard__kOUR8 .ReturnAction_buttonGroup__4uZ7I {
      grid-template-columns: 1fr;
      padding: var(--spacing-4) var(--spacing-4) 0 var(--spacing-1);
  }
    }
@media (min-width: 1024px) {
.ReturnAction_preApprovalCard__kOUR8 .ReturnAction_buttonGroup__4uZ7I {
      max-width: 668px;
  }
    }
@media (min-width: 1440px) {
.ReturnAction_preApprovalCard__kOUR8 .ReturnAction_buttonGroup__4uZ7I {
      max-width: 100%;
  }
    }
.ReturnAction_preApprovalCard__kOUR8 .ReturnAction_button__beZk4 {
    margin-bottom: var(--spacing-5);
    margin-left: 0;
    width: 100%;
  }
@media (max-width: 767px) {
      .ReturnAction_preApprovalCard__kOUR8 .ReturnAction_button__beZk4:first-child {
        order: 2;
      }
    }
.ReturnAction_preApprovalErrorIcon__X2VYB {
  text-align: center;
}
.ReturnAction_preApprovalWrapper__xRoAt {
  text-align: left;
}

.CallBackModal_callBackModalWrapper__t7sBy {
  width: 100%;
}

.CallBackModal_cta__ZY4we {
  width: 100%;
}

.CallBackModal_error__iuLzs {
  color: var(--colour-highlight-700, #e24529);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin: 32px 0px 20px;
}

.CallDealerForm_callDealerForm__VHSUd {
  padding: var(--spacing-8) 0;
  display: flex;
  width: 736px;
  align-items: center;
}

.CallDealerForm_callDealerForm__VHSUd button {
    min-width: 280px;
  }

.CallDealerForm_dealerInfo__94COT {
  display: flex;
  gap: var(--spacing-3);
}

.CallDealerForm_dealerInfo__94COT p {
    margin: 0;
  }

.CallDealerForm_modalContainer__HO9ZF {
  padding-bottom: var(--spacing-8);
}

.CallDealerForm_topPriority__gaFDz {
  order: 1;
}

.CallDealerForm_error__SlS9q {
  color: var(--color-red-700);
  font-size: 12px;
  line-height: 18px;
}

.LeadForm_modalContainer__DJ4w2 {
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {

.LeadForm_modalContainer__DJ4w2 {
    height: auto;
    height: initial;
    width: 100%;
}
  }

@media (min-width: 1024px) {

.LeadForm_modalContainer__DJ4w2 {
    height: auto;
    height: initial;
    width: 800px;
}
  }

.LeadForm_modalBodyClassName__q3TIU.LeadForm_modalBodyClassName__q3TIU {
    padding: 0;
  }

.LeadForm_dealerFormHeader__pZ5nz.LeadForm_dealerFormHeader__pZ5nz {
    margin: 0;
  }

.LeadForm_dealerFormHeader__pZ5nz span {
    font-weight: normal;
  }

.LeadForm_modalWrapper__PaxhJ {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.LeadForm_modalBody__dnxQV {
  overflow-y: auto;
  padding: 0 var(--spacing-5) 0 var(--spacing-5);
}

@media (min-width: 768px) {

.LeadForm_modalBody__dnxQV {
    max-height: calc(100vh - 64px);
    padding: 0 var(--spacing-6) 0 var(--spacing-6);
}
  }

@media (min-width: 1024px) {

.LeadForm_modalBody__dnxQV {
    max-height: calc(100vh - 180px);
    padding: 0 var(--spacing-10) 0 var(--spacing-10);
}
  }

.LeadForm_captchaWrapper__aOxdw {
  padding-bottom: 150px;
}

.LeadForm_modalFooter__oo_ia {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: var(--spacing-6);
  background-color: var(--color-championship-white);
  box-shadow: 0px 0px 13px 0px rgba(38, 38, 38, 0.14);
  z-index: 9999;
}

@media (max-width: 767px) {

.LeadForm_modalFooter__oo_ia {
    padding: var(--spacing-3) var(--spacing-6) var(--spacing-6);
}
  }

.LeadForm_enrichmentWrapper__zw0IY {
  margin-bottom: var(--spacing-4);
}

.LeadForm_enrichmentContentWrapper__2v6Xn.LeadForm_enrichmentBorder__hCSqT {
    margin-top: var(--spacing-4);
    border-top: 1px solid var(--color-neutral-300);
    border-bottom: 1px solid var(--color-neutral-300);
  }

.LeadForm_stroke__9geYn {
  border: none;
  height: 1px;
  color: var(--color-mica-blue-100);
  background-color: var(--color-mica-blue-100);
  margin: var(--spacing-5) 0;
}

@media (min-width: 768px) {

.LeadForm_stroke__9geYn {
    display: none;
}
  }

.HistoryCheck_reportStatusWrapper__wapuz {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: var(--spacing-8);
}

.HistoryCheck_reportStatusWrapper__wapuz button {
    margin-top: var(--spacing-8);
  }

.HistoryCheck_reportStatusWrapper__wapuz > div {
    width: 100%;
  }

.HistoryCheck_ctasWrapper__wNjK_ {
  display: flex;
  gap: var(--spacing-3);
  justify-content: center;
  flex-direction: column-reverse;
}

@media (min-width: 768px) {

.HistoryCheck_ctasWrapper__wNjK_ {
    flex-direction: row;
}
  }

.HistoryCheck_error__2QcHG {
  color: var(--colour-highlight-700, #e24529);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin: 32px 0px 20px;
}

.StatusDisplay_statusDisplayWrapper__fkrye {
  width: 100%;
}

@media (min-width: 1024px) {

.StatusDisplay_statusDisplayWrapper__fkrye {
    width: 800px;
}
  }

.StatusDisplay_statusDisplayBodyWrapper__TfU_i {
  display: flex;
  flex-direction: column;
  flex: 1 0;
  justify-content: center;
}

@media (min-width: 1024px) {

.StatusDisplay_statusDisplayBodyWrapper__TfU_i {
    display: block;
}
  }

.StatusDisplay_returnActionCta__UMqAk {
  text-align: center;
}

.StatusDisplay_resultSubhead__wWiIe,
.StatusDisplay_resultDescription__qiPJu {
  text-align: center;
}

.StatusDisplay_resultSubhead__wWiIe.StatusDisplay_leftAlign__jOkBV, .StatusDisplay_resultDescription__qiPJu.StatusDisplay_leftAlign__jOkBV {
    text-align: left;
    width: 100%;
  }

.StatusDisplay_resultDescription__qiPJu,
.StatusDisplay_resultSubhead__wWiIe,
.StatusDisplay_resultHeader__tgtvb {
  padding-top: var(--spacing-5);
}

.StatusDisplay_resultDescription__qiPJu.StatusDisplay_resultDescription__qiPJu, .StatusDisplay_resultDescription__qiPJu.StatusDisplay_resultSubhead__wWiIe, .StatusDisplay_resultDescription__qiPJu.StatusDisplay_resultHeader__tgtvb, .StatusDisplay_resultSubhead__wWiIe.StatusDisplay_resultDescription__qiPJu, .StatusDisplay_resultSubhead__wWiIe.StatusDisplay_resultSubhead__wWiIe, .StatusDisplay_resultSubhead__wWiIe.StatusDisplay_resultHeader__tgtvb, .StatusDisplay_resultHeader__tgtvb.StatusDisplay_resultDescription__qiPJu, .StatusDisplay_resultHeader__tgtvb.StatusDisplay_resultSubhead__wWiIe, .StatusDisplay_resultHeader__tgtvb.StatusDisplay_resultHeader__tgtvb {
    margin: 0;
  }

.StatusDisplay_resultSubhead__wWiIe + .StatusDisplay_resultDescription__qiPJu {
  padding-top: 0;
}

.StatusDisplay_resultMessageWrapper__7F7jN {
  display: flex;
  flex-direction: column;
  padding-top: var(--spacing-8);
  place-items: center;
}

.Error_errorWrapper__VqUzY {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-8);
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}
.Error_buttons__Nc9LY {
  margin: var(--spacing-8) 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.VideoPromo_layout__zcAjy {
  display: flex;
  flex-direction: row-reverse;
  gap: var(--spacing-6) var(--spacing-16);
  flex-wrap: wrap;
}

@media (min-width: 1440px) {

.VideoPromo_layout__zcAjy {
    gap: var(--spacing-5) 80px;
}
  }

.VideoPromo_content__a6N3j {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--spacing-5);
}

@media (min-width: 1024px) {

.VideoPromo_content__a6N3j {
    gap: var(--spacing-6);
    width: 448px;
}
  }

@media (min-width: 1440px) {

.VideoPromo_content__a6N3j {
    width: 477px;
}
  }

.VideoPromo_content__a6N3j.VideoPromo_content__a6N3j > * {
      margin: 0;
    }

.VideoPromo_content__a6N3j.VideoPromo_content__a6N3j > * p {
        margin: 0;
        padding: 0;
        line-height: 1.5;
      }

.VideoPromo_title__Gl1dO {
  line-height: 1.3;
}

@media (min-width: 1024px) {

.VideoPromo_title__Gl1dO {
    max-width: 350px;
}
  }

@media (max-width: 767px) {

.VideoPromo_title__Gl1dO {
    font-size: var(--spacing-6);
}
  }

.VideoPromo_cta__4kDLe {
  width: -moz-fit-content;
  width: fit-content;
}

.VideoPromo_text__Giz1f {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-5);
}

.VideoPromo_text__Giz1f > p > strong {
    font-weight: 500;
  }

@media (min-width: 1024px) {

.VideoPromo_text__Giz1f {
    max-width: 455px;
}
  }

.VideoPromo_video__PdwoQ {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(755px * 0.6);

  aspect-ratio: 16/9;
  background-color: #f5f5f5;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1024px) {

.VideoPromo_video__PdwoQ {
    flex: 1 1 auto;
    width: 448px;
    height: 280px;
    align-self: center;
}
  }

@media (min-width: 1440px) {

.VideoPromo_video__PdwoQ {
    width: 755px;
    height: auto;
    height: initial;
}
  }

.VideoPromo_playIcon__P_ThL {
  position: absolute;
  z-index: 11;
  color: rgba(255, 255, 255, 0.8);
  background: rgba(0, 0, 0, 0.6);
  border-radius: 1000px;
  height: 150px;
  width: 150px;
  padding: var(--spacing-8);
  padding-right: var(--spacing-5);

  cursor: pointer;
  pointer-events: none;
  transition: 0.2s ease-in-out;
  transform: scale(1);
}

.VideoPromo_playIcon__P_ThL[data-has-started='true'] {
    transition: 0.6s ease-in-out;
    transform: scale(1.3);
    opacity: 0;
    pointer-events: none;
  }

@media (max-width: 767px) {

.VideoPromo_playIcon__P_ThL {
    height: 80px;
    width: 80px;
    padding: var(--spacing-6);
    padding-right: var(--spacing-4);
}
  }

.VideoPromo_thumbnail__wcesJ {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center;
  opacity: 1;
  z-index: 10;
  transition: opacity 1.4s ease-in;
  cursor: pointer;
}

.VideoPromo_thumbnail__wcesJ[data-has-started='true'] {
    opacity: 0;
    pointer-events: none;
  }

.VideoPromo_thumbnail__wcesJ:hover + .VideoPromo_playIcon__P_ThL {
      /* transition: 0.1s linear; */
      transform: scale(1.05);
    }

.VideoPromo_dividerSpacing__7gXK5 {
  margin: 0 0 var(--spacing-10);
}

@media (min-width: 1024px) {

.VideoPromo_dividerSpacing__7gXK5 {
    margin: var(--spacing-16) 0;
}
  }

@media (min-width: 1440px) {

.VideoPromo_dividerSpacing__7gXK5 {
    margin: var(--spacing-20) 0;
}
  }

.Interlinking_interlinking__SbSfF {
  display: grid;
  grid-auto-flow: row;
  width: 100%;
}

@media (min-width: 1024px) {

.Interlinking_interlinking__SbSfF {
    grid-auto-flow: column;
    grid-template-columns: max-content;
}
  }

.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF h6 {
      margin: 0;
    }

.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF[data-accordion-component] {
      width: 100%;
    }

.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF [data-accordion-component='AccordionItemPanel'] {
      width: 100%;
    }

.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF h6 {
      font-size: clamp(14px, 1.1vw, 16px);
      line-height: 1.3;
    }

.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_title__OIXiG {
      width: 100%;
    }

@media (min-width: 1024px) {

.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_title__OIXiG {
        width: 302px;
        margin-right: var(--spacing-16);
    }
      }

@media (min-width: 1280px) {

.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_title__OIXiG {
        width: 365px;
        margin-right: var(--spacing-20);
    }
      }

.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_layout__ZZwOl {
      display: grid;
      grid-auto-flow: row;
      grid-gap: var(--spacing-6);
      gap: var(--spacing-6);

      white-space: normal;
      text-align: left;
    }

@media (min-width: 768px) {

.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_layout__ZZwOl {
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
    }
      }

@media (min-width: 1024px) {
        .Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_layout__ZZwOl > div:nth-child(3n + 1) {
          grid-column: 1;
        }
        .Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_layout__ZZwOl > div:nth-child(3n + 2) {
          grid-column: 2;
        }
        .Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_layout__ZZwOl > div:nth-child(3n + 3) {
          grid-column: 3;
        }
      }

@media (min-width: 1280px) {
        .Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_layout__ZZwOl > div:nth-child(3n + 4) {
          grid-column: 4;
        }
      }

.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_layout__ZZwOl a {
        white-space: normal;
        text-align: left;
      }

.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_locationLayout__xoSF5 {
      grid-auto-flow: row;
      gap: var(--spacing-6);
      white-space: normal;
      text-align: left;
    }

.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_locationLayout__xoSF5.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_locationLayout__xoSF5 {
        margin: 0;
        padding: 0;
      }

@media (min-width: 768px) {

.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_locationLayout__xoSF5 {
        grid-template-columns: repeat(4, 1fr);
    }
      }

.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_hideSectionForTblt__Qy0aB {
      display: none;
    }

@media (min-width: 768px) {

.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_hideSectionForTblt__Qy0aB {
        display: grid;
        padding: 0;
    }
      }

.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_mobileView__vEZSh {
      display: grid;
      padding: 0;
    }

@media (min-width: 768px) {

.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_mobileView__vEZSh {
        display: none;
    }
      }

.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_mobileView__vEZSh .Interlinking_viewMore__gGwm3 {
        width: -moz-fit-content;
        width: fit-content;
      }

.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_blockHeader__Fh_Xd {
      font-weight: bold;
      margin: var(--spacing-2) 0;
    }

@media (min-width: 768px) {

.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_blockHeader__Fh_Xd {
        min-height: var(--spacing-9);
    }
      }

@media (min-width: 1280px) {

.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_blockHeader__Fh_Xd {
        min-height: var(--spacing-10);
    }
      }

.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_list__nAaDT {
      display: grid;
      grid-gap: var(--spacing-6);
      gap: var(--spacing-6);
    }

@media (min-width: 768px) {

.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_list__nAaDT {
        grid-auto-rows: max-content;
    }
      }

.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_list__nAaDT h6 {
        margin: var(--spacing-2) 0;
      }

@media (min-width: 768px) {

.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_list__nAaDT h6 {
          min-height: var(--spacing-9);
      }
        }

@media (min-width: 1280px) {

.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_list__nAaDT h6 {
          min-height: var(--spacing-10);
      }
        }

.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_list__nAaDT a {
        text-decoration: none;
      }

.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_list__nAaDT button {
        min-height: auto;
      }

.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_links__O2Kul {
      text-align: left;
      text-decoration: none;
      white-space: normal;
    }

.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_links__O2Kul button {
        padding: 0;
      }

.Interlinking_interlinking__SbSfF.Interlinking_interlinking__SbSfF .Interlinking_links__O2Kul span {
        font-size: clamp(14px, 1.1vw, 16px);
        font-weight: var(--font-weight-regular);
        line-height: 1.3;
      }

:scope [data-tenant='rac'] .Interlinking_layout__ZZwOl button {
      color: var(--color-rac-grey-100);
      transition: 0.1s;
    }

:scope [data-tenant='rac'] .Interlinking_layout__ZZwOl button:hover {
        color: var(--color-primary-500);
      }

.Header_headerContainer__U17nV {
  margin-bottom: 20px;
}
.Header_headerContainer__U17nV h3,
  .Header_headerContainer__U17nV p {
    margin: 0 0 9px;
    position: relative;
  }
.Header_header__gBomM h4,
  .Header_header__gBomM p {
    margin-bottom: var(--spacing-2);
    margin-top: 0;
  }
.Header_link__QnXuR {
  color: var(--neutral-700-pressed-default-text, #303030);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.2px;
  text-decoration-line: underline;
}
.Header_products__ysDn7 {
  margin-top: var(--spacing-3);
  display: flex;
  flex-direction: column;
  gap: 1px;
}
.Header_product__WnZSC {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  position: relative;
}
.Header_product__WnZSC strong {
    font-weight: 500;
  }
.Header_findOut__7p3V_ {
  text-decoration-line: underline;
  cursor: pointer;
}


  @media (min-width: 1024px) {

.LegalDisclaimers_modalContainer__rnTZM {
    height: 635px;
    width: 800px;
}
  }

.LegalDisclaimers_modalBody__PGEpf {
  overflow: auto;
}

.Calculator_calculatorWrapper__P6kBZ {
  position: relative;
  margin: 0 0 var(--spacing-10) 0;
  border: 1px solid var(--neutral-300-disabled, #b5b5b5);

  --color-mica-blue-500: #164ca3;

  --color-primary-50: var(--color-mica-blue-50);
  --color-primary-100: var(--color-mica-blue-100);
  --color-primary-200: var(--color-mica-blue-200);
  --color-primary-300: var(--color-mica-blue-300);
  --color-primary-400: var(--color-mica-blue-400);
  --color-primary-500: var(--color-mica-blue-500);
  --color-primary-600: var(--color-mica-blue-600);
  --color-primary-700: var(--color-mica-blue-700);
  --color-primary-800: var(--color-mica-blue-800);
  --color-primary-900: var(--color-mica-blue-900);

  --color-secondary-50: var(--color-heycar-mint-50);
  --color-secondary-100: var(--color-heycar-mint-100);
  --color-secondary-200: var(--color-heycar-mint-200);
  --color-secondary-300: var(--color-heycar-mint-300);
  --color-secondary-400: var(--color-heycar-mint-400);
  --color-secondary-500: var(--color-heycar-mint-500);
  --color-secondary-600: var(--color-heycar-mint-600);
  --color-secondary-700: var(--color-heycar-mint-700);
  --color-secondary-800: var(--color-heycar-mint-800);
  --color-secondary-900: var(--color-heycar-mint-900);

  --button-primary-base-bg-color: var(--color-primary-500);
  --button-primary-hover-bg-color: var(--color-primary-600);
  --button-primary-active-bg-color: var(--color-primary-700);
}

@media (min-width: 1024px) {

.Calculator_calculatorWrapper__P6kBZ {
    margin: var(--spacing-6) 0 var(--spacing-12) 0;
}
  }

.Calculator_calculatorWrapper__P6kBZ > *:not(:last-child) {
    margin: 0 0 var(--spacing-10) 0;
  }

@media (min-width: 1024px) {

.Calculator_calculatorWrapper__P6kBZ > *:not(:last-child) {
      margin: var(--spacing-6) 0 var(--spacing-12) 0;
  }
    }

.Calculator_link__LYQS1 > span {
    color: var(--neutral-700-pressed-default-text, #303030);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
    text-decoration-line: underline;
  }

.Calculator_mainWrapper__WPQXw {
  position: relative;
  z-index: 1;
  max-width: 100%;
  padding: var(--spacing-10) var(--spacing-5);
  border-top: 10px solid var(--primary-50-surface, #f1f4fa);
}

@media (min-width: 768px) {

.Calculator_mainWrapper__WPQXw {
    padding: var(--spacing-10) var(--spacing-6);
}
  }

@media (min-width: 1024px) {

.Calculator_mainWrapper__WPQXw {
    padding: var(--spacing-10) var(--spacing-8);
}
  }

@media (min-width: 1440px) {

.Calculator_mainWrapper__WPQXw {
    padding: var(--spacing-10);
}
  }

.Calculator_errorLabel__006t1 {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 var(--spacing-4) 0 var(--spacing-4);
  line-height: 26px;
}

@media (min-width: 768px) {
    .Calculator_errorLabel__006t1 p {
      line-height: 26px;
    }
  }

.Calculator_mainOverlay__9PiXG {
  position: absolute;
  top: -9px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 103%;
  background: linear-gradient(to top, #fff1ef 50%, rgba(255, 255, 255, 0.8) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
}

@media (max-width: 767px) {

.Calculator_mainOverlay__9PiXG {
    top: -12px;
    height: 102%;
}
  }

@media (min-width: 768px) {

.Calculator_mainOverlay__9PiXG {
    background: linear-gradient(to left, #fff1ef 50%, rgba(255, 255, 255, 0.8) 100%);
}
  }

.Calculator_mainOverlay__9PiXG.Calculator_visible__H9gvX {
  visibility: visible;
  opacity: 1;
  display: flex;
  flex-direction: column;
}

.Calculator_formContainer__ebBX2 {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  gap: var(--spacing-8);
}

@media (min-width: 768px) {

.Calculator_formContainer__ebBX2 {
    flex-direction: row;
    gap: var(--spacing-6);
}
  }

@media (min-width: 1024px) {

.Calculator_formContainer__ebBX2 {
    gap: var(--spacing-8);
}
  }

@media (min-width: 1280px) {

.Calculator_formContainer__ebBX2 {
    gap: var(--spacing-10);
}
  }

.Calculator_content__UlnM6 {
  flex: 1 1 200px;
}

.Calculator_content__UlnM6 > div {
    margin-bottom: 0;
  }

.Calculator_divider__aMKoK {
  border-top: 1px solid var(--color-tarmac-grey-300);
}

@media (min-width: 768px) {

.Calculator_divider__aMKoK {
    border-top: none;
    border-left: 1px solid var(--color-tarmac-grey-300);
}
  }

.Calculator_summaryContainer__GbhbB {
  flex: 1 1 200px;
}

.Calculator_summaryContainer__GbhbB h5,
  .Calculator_summaryContainer__GbhbB p {
    margin-bottom: var(--spacing-2);
    margin-top: 0;
  }

.Calculator_summaryContainer__GbhbB > *,
  .Calculator_summaryContainer__GbhbB > p {
    margin-bottom: var(--spacing-5);
  }

@media (min-width: 768px) {

.Calculator_summaryContainer__GbhbB {
    max-width: calc(50% - 18px);
}
  }

.Calculator_universalSummaryContainer__BzToZ {
  margin-top: var(--spacing-5);
}

.Calculator_universalSummaryContainer__BzToZ h5,
  .Calculator_universalSummaryContainer__BzToZ p {
    margin-bottom: var(--spacing-2);
    margin-top: 0;
  }

.Calculator_universalSummaryContainer__BzToZ > *,
  .Calculator_universalSummaryContainer__BzToZ > p {
    margin-bottom: var(--spacing-5);
  }

.Calculator_summaryFakeContainer__ifnVp {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767px) {

.Calculator_summaryFakeContainer__ifnVp {
    flex-direction: column;
    align-items: flex-start;
}
  }

.Calculator_ctasContainer__QKG4h {
  width: auto;
  text-align: center;
}

.Calculator_ctasContainer__QKG4h > :first-child {
    margin-bottom: var(--spacing-3);
  }

@media (min-width: 768px) {

.Calculator_ctasContainer__QKG4h > :first-child {
      margin-bottom: 0;
  }
    }

.Calculator_universalCtasContainer__znLIx {
  text-align: right;
}

.Calculator_universalCtasContainer__znLIx > :first-child {
    margin-bottom: var(--spacing-3);
  }

@media (min-width: 768px) {

.Calculator_universalCtasContainer__znLIx > :first-child {
      margin-bottom: 0;
  }
    }

.Calculator_universalButton__7EMZv {
  width: 280px;
}

@media (max-width: 767px) {

.Calculator_universalButton__7EMZv {
    width: 100%;
}
  }

.Calculator_summaryTitle__brbPX [class*='typography'] {
    position: relative;
  }

.Calculator_fakeSummary__zjeoh {
  height: 380px;
  position: relative;
}

.Calculator_universalFakeSummary__ebid5 {
  height: 180px;
  position: relative;
}

.Calculator_fakeCheckoutSummary__YaGYM {
  height: 180px;
  display: flex;
  flex-direction: row;
}

.Calculator_fakeButton__eaDCP {
  height: 43px;
  width: 100%;
  position: relative;
}

.Calculator_fakeButtons__vemVE {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
  margin-bottom: var(--spacing-8) !important;
  max-width: 100%;
  position: relative;
}

@media (min-width: 1024px) {

.Calculator_modalContainer__lHSIN {
    height: 635px;
    width: 800px;
}
  }

@media (min-width: 1024px) {

.Calculator_emailModalContainer__hPpJ0 {
    width: 800px;
    height: 330px;
}
  }

.Calculator_modalBody__DWz03 {
  overflow: auto;
}

.Calculator_error__MW3g_ {
  flex: 1 1 200px;
}

.Calculator_error__MW3g_ div {
    background-color: var(--color-red-50);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin: 0 auto var(--spacing-5);
    padding: var(--spacing-5);
  }

.Calculator_error__MW3g_ svg {
    margin: 0 auto;
  }

.Calculator_error__MW3g_ h5,
  .Calculator_error__MW3g_ p,
  .Calculator_error__MW3g_ svg {
    margin-bottom: var(--spacing-5);
    margin-top: 0;
  }

.Calculator_error__MW3g_ > *,
  .Calculator_error__MW3g_ > p {
    margin-bottom: var(--spacing-5);
  }

@media (min-width: 768px) {

.Calculator_error__MW3g_ {
    max-width: calc(100% - 18px);
}
  }

.Calculator_financePreApprovalWrapper__xjxUo {
  text-align: left;
}

.Calculator_financePreApprovalTitle__eAZq2 {
  margin: var(--spacing-4) 0 var(--spacing-2) 0;
}

.Calculator_financePreApprovalTitle__eAZq2 > p {
    font-size: 16px;
  }

.Calculator_financePreApprovalErrorIcon__WhP8j {
  text-align: center;
}

.Calculator_financePreApprovalCta__b9zNJ {
  margin-top: var(--spacing-5);
}

.Calculator_lenderNote__vuTmg {
  display: flex;
  text-align: start;
  margin-bottom: var(--spacing-3) !important;
}

.Calculator_supportOptIn__z4pBk label {
    margin: var(--spacing-5) 0;
    display: flex;
    gap: var(--spacing-3);
    align-items: start;
    -webkit-user-select: none;
            user-select: none;
  }

.Calculator_supportOptIn__z4pBk p {
    display: flex;
    align-items: center;
    margin-top: var(--spacing-6);
    font-weight: 500;
  }

.Summary_container__MmkUe {
  padding: 14px;
  background-color: #f1f4fa;
  list-style: none;
}

.Summary_summary__QTtFt {
  list-style: none;
  padding: 0;
  margin: 0;
}

.Summary_summary__QTtFt > li {
    border-bottom: 1px #b5b5b5;
  }

.Summary_summary__QTtFt > li:first-child {
      border-bottom-style: dashed;
      padding: 8px 0;
    }

.Summary_summary__QTtFt > li:last-child {
      border-bottom: none;
    }

.Summary_summary__QTtFt li.Summary_instalments__Qh562 {
    margin: 0;
    display: flex;
    font-weight: 700;
    font-size: 16px;
  }

.Summary_summary__QTtFt li.Summary_instalments__Qh562 span:first-child {
      flex-basis: 70%;
    }

.Summary_summary__QTtFt li.Summary_instalments__Qh562 span:last-child {
      text-align: right;
      flex-basis: 70%;
    }

.Summary_summary__QTtFt li > span > .Summary_monthlyRate__Y8MRC {
    min-width: 188px;
    white-space: wrap;
    font-size: 12px;
  }

.Summary_summary__QTtFt li.Summary_additionalServices__zU4OC span:first-child {
      flex-basis: 60%;
    }

.Summary_summary__QTtFt li > ul {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
  }

.Summary_summary__QTtFt li > ul li {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
    }

.Summary_summary__QTtFt li > ul li span:first-child {
        flex-basis: 55%;
      }

.Summary_summary__QTtFt li > ul li span:last-child {
        font-weight: 500;
        text-align: right;
      }

.Summary_appFeeContent__jMKEP {
  margin-left: 14px;
  white-space: nowrap;
}

.Summary_infoIcon__JMoLp {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  padding: 0 var(--spacing-1) 0 var(--spacing-1);
}

.Summary_divider__qv_bv {
  border-bottom: 1px solid var(--neutral-300, #b5b5b5);
  margin: var(--spacing-4) 0 var(--spacing-4) !important;
}

@font-face {
font-family: '__objektiv_516fde';
src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/3fcd33a27296ff83-s.p.woff2) format('woff2');
font-display: block;
font-weight: 100;
}

@font-face {
font-family: '__objektiv_516fde';
src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/3fcd33a27296ff83-s.p.woff2) format('woff2');
font-display: block;
font-weight: 400;
}

@font-face {
font-family: '__objektiv_516fde';
src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/3fcd33a27296ff83-s.p.woff2) format('woff2');
font-display: block;
font-weight: normal;
}

@font-face {
font-family: '__objektiv_516fde';
src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/9c496f57d16399a3-s.p.woff2) format('woff2');
font-display: block;
font-weight: 500;
}

@font-face {
font-family: '__objektiv_516fde';
src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/eb5c97377ced185c-s.p.woff2) format('woff2');
font-display: block;
font-weight: 600 700;
}

@font-face {
font-family: '__objektiv_516fde';
src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/6e2115458c57af1d-s.p.woff2) format('woff2');
font-display: block;
font-weight: bold;
}@font-face {font-family: '__objektiv_Fallback_516fde';src: local("Arial");ascent-override: 103.86%;descent-override: 27.93%;line-gap-override: 0.00%;size-adjust: 109.19%
}.__className_516fde {font-family: '__objektiv_516fde', '__objektiv_Fallback_516fde', Objektiv, sans-serif
}.__variable_516fde {--font-family-system: '__objektiv_516fde', '__objektiv_Fallback_516fde', Objektiv, sans-serif
}

.ProductToggle_financeOptionsType__kk0sg {
  display: flex;
  flex-direction: row;
  border: 1px solid var(--color-tarmac-grey-300);
  margin: 0;
  padding: 0;
  height: 48px;
  position: relative;
}

.ProductToggle_financeOption__Z_cLJ {
  flex: 1 1;
  text-align: center;
  font-weight: 500;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  background: white;
  transition: all 0.2s ease;
  border-right: 1px solid var(--color-tarmac-grey-300);
  margin: 0;
}

@media (max-width: 767px) {

.ProductToggle_financeOption__Z_cLJ {
    height: 48px;
    border-right: none;
    border-bottom: 1px solid var(--color-tarmac-grey-300);
}

    .ProductToggle_financeOption__Z_cLJ:last-child {
      border-bottom: none;
    }
  }

.ProductToggle_financeOption__Z_cLJ:last-child {
    border-right: none;
  }

.ProductToggle_financeOption__Z_cLJ > * {
    cursor: pointer;
  }

.ProductToggle_financeOption__Z_cLJ > input {
    opacity: 0;
    height: 0;
    width: 0;
  }

.ProductToggle_financeOption__Z_cLJ > label {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 var(--spacing-2);
  }

.ProductToggle_js-has-pseudo__YLSns [csstools-has-1a-2u-2x-32-2p-32-2r-2t-27-34-38-2x-33-32-1m-2w-2p-37-14-2x-32-34-39-38-2j-38-3d-34-2t-1p-13-36-2p-2s-2x-33-13-2l-1m-2r-2w-2t-2r-2z-2t-2s-15]:not(.ProductToggle_does-not-exist__dN1aZ):not(.ProductToggle_does-not-exist__dN1aZ):not(does-not-exist) {
    background: var(--color-mica-blue-50);
    border: 2px solid var(--color-mica-blue-500);
    color: var(--color-mica-blue-500);
    margin: -1px;
    z-index: 1;
    height: calc(100% + 2px);
  }

.ProductToggle_financeOption__Z_cLJ:has(input[type='radio']:checked) {
    background: var(--color-mica-blue-50);
    border: 2px solid var(--color-mica-blue-500);
    color: var(--color-mica-blue-500);
    margin: -1px;
    z-index: 1;
    height: calc(100% + 2px);
  }

@media (max-width: 767px) {

.ProductToggle_js-has-pseudo__YLSns [csstools-has-1a-2u-2x-32-2p-32-2r-2t-27-34-38-2x-33-32-1m-2w-2p-37-14-2x-32-34-39-38-2j-38-3d-34-2t-1p-13-36-2p-2s-2x-33-13-2l-1m-2r-2w-2t-2r-2z-2t-2s-15]:not(.ProductToggle_does-not-exist__dN1aZ):not(.ProductToggle_does-not-exist__dN1aZ):not(does-not-exist) {
      height: calc(48px + 2px);
  }

.ProductToggle_financeOption__Z_cLJ:has(input[type='radio']:checked) {
      height: calc(48px + 2px);
  }
    }

.ProductToggle_divider__ca4y6 {
  border-bottom: 1px solid var(--neutral-100, #e6e6e6);
  margin: var(--spacing-6) 0 var(--spacing-6) !important;
}

@media (max-width: 767px) {
  .ProductToggle_financeOptionsType__kk0sg {
    flex-direction: column;
    height: auto;
  }

  .ProductToggle_js-has-pseudo__YLSns [csstools-has-1a-2u-2x-32-2p-32-2r-2t-27-34-38-2x-33-32-37-2c-3d-34-2t-1m-2w-2p-37-14-1a-2u-2x-32-2p-32-2r-2t-27-34-38-2x-33-32-1m-32-38-2w-19-2r-2w-2x-30-2s-14-1e-15-1m-30-2p-37-38-19-2r-2w-2x-30-2s-15]:not(.ProductToggle_does-not-exist__dN1aZ):not(.ProductToggle_does-not-exist__dN1aZ):not(.ProductToggle_does-not-exist__dN1aZ) {
    min-height: 96px;
  }

  .ProductToggle_financeOptionsType__kk0sg:has(.ProductToggle_financeOption__Z_cLJ:nth-child(2):last-child) {
    min-height: 96px;
  }

  .ProductToggle_js-has-pseudo__YLSns [csstools-has-1a-2u-2x-32-2p-32-2r-2t-27-34-38-2x-33-32-37-2c-3d-34-2t-1m-2w-2p-37-14-1a-2u-2x-32-2p-32-2r-2t-27-34-38-2x-33-32-1m-32-38-2w-19-2r-2w-2x-30-2s-14-1e-15-1m-30-2p-37-38-19-2r-2w-2x-30-2s-15-w-1a-2u-2x-32-2p-32-2r-2t-27-34-38-2x-33-32]:not(.ProductToggle_does-not-exist__dN1aZ):not(.ProductToggle_does-not-exist__dN1aZ):not(.ProductToggle_does-not-exist__dN1aZ):not(.ProductToggle_does-not-exist__dN1aZ) {
    height: 48px;
    min-height: 48px;
    flex: 0 0 48px;
  }

  .ProductToggle_financeOptionsType__kk0sg:has(.ProductToggle_financeOption__Z_cLJ:nth-child(2):last-child) .ProductToggle_financeOption__Z_cLJ {
    height: 48px;
    min-height: 48px;
    flex: 0 0 48px;
  }

  .ProductToggle_js-has-pseudo__YLSns [csstools-has-1a-2u-2x-32-2p-32-2r-2t-27-34-38-2x-33-32-37-2c-3d-34-2t-1m-2w-2p-37-14-1a-2u-2x-32-2p-32-2r-2t-27-34-38-2x-33-32-1m-32-38-2w-19-2r-2w-2x-30-2s-14-1f-15-15]:not(.ProductToggle_does-not-exist__dN1aZ):not(.ProductToggle_does-not-exist__dN1aZ) {
    min-height: 144px;
  }

  .ProductToggle_financeOptionsType__kk0sg:has(.ProductToggle_financeOption__Z_cLJ:nth-child(3)) {
    min-height: 144px;
  }

  .ProductToggle_js-has-pseudo__YLSns [csstools-has-1a-2u-2x-32-2p-32-2r-2t-27-34-38-2x-33-32-37-2c-3d-34-2t-1m-2w-2p-37-14-1a-2u-2x-32-2p-32-2r-2t-27-34-38-2x-33-32-1m-32-38-2w-19-2r-2w-2x-30-2s-14-1f-15-15-w-1a-2u-2x-32-2p-32-2r-2t-27-34-38-2x-33-32]:not(.ProductToggle_does-not-exist__dN1aZ):not(.ProductToggle_does-not-exist__dN1aZ):not(.ProductToggle_does-not-exist__dN1aZ) {
    height: 48px;
    min-height: 48px;
    flex: 1 1;
  }

  .ProductToggle_financeOptionsType__kk0sg:has(.ProductToggle_financeOption__Z_cLJ:nth-child(3)) .ProductToggle_financeOption__Z_cLJ {
    height: 48px;
    min-height: 48px;
    flex: 1 1;
  }
}

.Scroller_wrapper__XDcFM {
  -webkit-user-select: none;
          user-select: none;
  position: relative;
}

.Scroller_fullWidth__ub5d_ {
  --internalGap: var(--spacing-5);
  --maxPaddingWidth: min(1340px, 100vw - (2 * var(--internalGap)));
  --sidePadding: calc((100vw - var(--maxPaddingWidth)) / 2);

  position: relative;
  left: calc(var(--sidePadding) * -1);
  right: calc(var(--sidePadding) * -1);
  width: 100vw;
}

@media (min-width: 768px) {

.Scroller_fullWidth__ub5d_ {
    --internalGap: var(--spacing-6);
}
  }

@media (min-width: 1024px) {

.Scroller_fullWidth__ub5d_ {
    --internalGap: var(--spacing-8);
}
  }

@media (min-width: 1366px) {

.Scroller_fullWidth__ub5d_ {
    --internalGap: var(--spacing-8);
    --maxPaddingWidth: min(1373px, 100vw - (2 * var(--internalGap)));
}
  }

@media (min-width: 1440px) {

.Scroller_fullWidth__ub5d_ {
    --internalGap: var(--spacing-16);
    --maxPaddingWidth: min(1312px, 100vw - (2 * var(--internalGap)));
}
  }

.Scroller_nav__vJD5F {
  position: absolute;
  right: 0;
  top: -70px;

  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: var(--spacing-6);
}

@media (min-width: 1024px) {

.Scroller_nav__vJD5F {
    display: flex;
}
  }

.Scroller_nav__vJD5F svg {
    height: var(--spacing-9);
    width: var(--spacing-9);
    padding: 0 10px;
    cursor: pointer;

    opacity: 0.3;
  }

.Scroller_nav__vJD5F svg[data-is-active='true'] {
      opacity: 1;
    }

.Scroller_scrollBox__c0EkS {
  width: 100vw;
  scroll-snap-type: x proximity;
  overflow-x: scroll;
  position: relative;
  /* left: calc(-1 * var(--sidePadding)); */
  padding: 0 var(--sidePadding);
  scroll-padding-left: var(--sidePadding);
  scroll-padding-right: var(--sidePadding);
  display: flex;
  flex-direction: row;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.Scroller_scrollBox__c0EkS::-webkit-scrollbar {
    display: none;
  }

[data-tenant='rac'] .Scroller_nav__vJD5F svg,
    [data-tenant='rac'] .Scroller_nav__vJD5F path {
      fill: var(--color-primary-500);
    }

[data-tenant='rac'] .Scroller_nav__vJD5F svg {
      height: var(--spacing-10);
      width: var(--spacing-10);
    }

.Scroller_dots___zXwp {
  display: flex;
  gap: var(--spacing-1);
}

.Scroller_dot__5Uc1z {
  background-color: var(--color-primary-200);
  height: var(--spacing-2);
  width: var(--spacing-2);
  border-radius: 50%;
}

.Scroller_dot__5Uc1z[data-is-active='true'] {
    background-color: var(--color-primary-500);
  }

.FilteredVehicleCarousel_wrapper__KB0Ot {
  width: 100%;
  padding-bottom: var(--spacing-16);
  padding-top: var(--spacing-16);
}

@media (min-width: 1024px) {

.FilteredVehicleCarousel_wrapper__KB0Ot .reviews-scrollbox {
      width: 100%;
      overflow-y: hidden;
  }
    }

.FilteredVehicleCarousel_wrapper__KB0Ot .reviews-nav {
    display: none;
  }

@media (min-width: 1024px) {

.FilteredVehicleCarousel_wrapper__KB0Ot .reviews-nav {
      display: flex;
      padding-top: var(--spacing-4);
      top: auto;
  }
    }

.FilteredVehicleCarousel_wrapper__KB0Ot.FilteredVehicleCarousel_hasBorderBottom__7Br1z {
    border-bottom: 1px solid var(--color-tarmac-grey-300);
  }

.FilteredVehicleCarousel_titleWrapper__fP9lk {
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
}

@media (min-width: 768px) {

.FilteredVehicleCarousel_titleWrapper__fP9lk {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: var(--spacing-6);
}
  }

.FilteredVehicleCarousel_titleWrapper__fP9lk h2 {
    margin: 0;
  }

.FilteredVehicleCarousel_cta__MH5UP {
  padding-top: 0;
  width: 100%;
  color: var(--neutral-700);
  justify-content: start;
}

@media (min-width: 768px) {

.FilteredVehicleCarousel_cta__MH5UP {
    justify-self: flex-end;
    width: auto;
    padding-top: var(--spacing-6);
}
  }

.FilteredVehicleCarousel_reel__pfc69 {
  display: flex;
  list-style: none;
  margin: 0 auto;
  padding: 0;
  gap: var(--spacing-4);
}

@media (min-width: 1024px) {

.FilteredVehicleCarousel_reel__pfc69 {
    width: 100%;
}
  }

.FilteredVehicleCarousel_reviews__bvkn3 {
  background: var(--color-championship-white);
  min-width: 300px;
}

.FilteredVehicleCarousel_reviews__bvkn3 article {
    height: 100%;
  }

@media (min-width: 768px) {

.FilteredVehicleCarousel_reviews__bvkn3 {
    flex-grow: 1;
}

    .FilteredVehicleCarousel_reviews__bvkn3 > a {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }


  @media (min-width: 1024px) {

.FaqSection_root___AfZK {
    display: grid;
    grid-template-columns: [left] 1fr [right] 1fr;
    grid-column-gap: var(--spacing-4);
    column-gap: var(--spacing-4);
}
  }

@media (min-width: 1280px) {

.FaqSection_root___AfZK {
    display: grid;
    grid-template-columns: [left] 0.706fr [right] 1fr;
    grid-column-gap: var(--spacing-4);
    column-gap: var(--spacing-4);
}
  }

@media (min-width: 768px) {

.FaqSection_title__p6pAH.FaqSection_title__p6pAH {
      grid-column: left;
      margin-bottom: var(--spacing-7);
  }
    }

.FaqSection_description__zF7PC {
  grid-column: right;
  margin-bottom: var(--spacing-6);
}

@media (min-width: 1024px) {

.FaqSection_description__zF7PC {
    margin-top: 0;
}
  }

.FaqSection_accordions__jwu5n {
  grid-column: right;
}

.FaqSection_accordionTitle__LsYA9 {
  margin: 0;
}

.FaqSection_button__99MVO {
  justify-self: flex-start;
  grid-column: right;
  margin-top: var(--spacing-6);
}


  .LandingPageBlock_landingPageBlock__8vBVx + .LandingPageBlock_landingPageBlock__8vBVx {
    margin-top: -80px;
  }

.LandingPageBlock_landingPageBlock__8vBVx .LandingPageBlock_contentWrapper__wWNVE {
    padding-top: var(--spacing-20);
    padding-bottom: var(--spacing-20);
  }

.LandingPageBlock_landingPageBlock__8vBVx[data-theme='borderless'] {
    background-color: var(--color-white);
  }

.LandingPageBlock_landingPageBlock__8vBVx[data-theme='borderless'] .LandingPageBlock_contentWrapper__wWNVE {
      padding-top: 0;
      padding-bottom: 0;
    }

.LandingPageBlock_contentWrapper__wWNVE.LandingPageBlock_contentWrapper__wWNVE {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-10) var(--spacing-5);
  }

@media (min-width: 1024px) {

.LandingPageBlock_contentWrapper__wWNVE.LandingPageBlock_contentWrapper__wWNVE {
      flex-direction: row;
      padding-right: var(--spacing-8);
      padding-left: var(--spacing-8);
  }
    }

@media (min-width: 1440px) {

.LandingPageBlock_contentWrapper__wWNVE.LandingPageBlock_contentWrapper__wWNVE {
      padding-right: var(--spacing-16);
      padding-left: var(--spacing-16);
  }
    }

@media (min-width: 1024px) {

.LandingPageBlock_contentWrapper__wWNVE.LandingPageBlock_contentWrapper__wWNVE.LandingPageBlock_imageAlignRight__7hhRF {
        flex-direction: row-reverse;
    }
      }

.LandingPageBlock_contentWrapper__wWNVE.LandingPageBlock_contentWrapper__wWNVE.LandingPageBlock_imageAlignRight__7hhRF .LandingPageBlock_copyContainer__Zi3v7 {
        padding-left: 0;
      }

@media (min-width: 1024px) {

.LandingPageBlock_contentWrapper__wWNVE.LandingPageBlock_contentWrapper__wWNVE.LandingPageBlock_imageAlignRight__7hhRF .LandingPageBlock_copyContainer__Zi3v7 {
          padding-right: var(--spacing-10);
      }
        }

.LandingPageBlock_imageContainer__mDzqy {
  position: relative;
  margin-bottom: var(--spacing-6);
}

@media (min-width: 1024px) {

.LandingPageBlock_imageContainer__mDzqy {
    flex: 1 1 50%;
    margin-bottom: 0;
}
  }

.LandingPageBlock_imageContainer__mDzqy img {
    object-fit: contain;
    position: relative !important;
  }

@media (min-width: 1024px) {

.LandingPageBlock_copyContainer__Zi3v7 {
    flex: 1 1 50%;
    padding-left: var(--spacing-10);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
  }

.LandingPageBlock_copyContainer__Zi3v7 h3.LandingPageBlock_copyContainer__Zi3v7 h3 {
      margin-top: 0;
    }

.LandingPageBlock_headline__x7fCB {
  font-size: 24px;
}

@media (min-width: 768px) {

.LandingPageBlock_headline__x7fCB {
    font-size: 28px;
}
  }

.LandingPageBlock_cta__XiFqK {
  width: 100%;
  max-width: none;
  max-width: initial;
}

@media (min-width: 1024px) {

.LandingPageBlock_cta__XiFqK {
    max-width: 310px;
}
  }

.LandingPageBlock_copy__dr_d_ {
  margin-bottom: var(--spacing-8);
}

.LandingPageBlock_headerLogo__Km1vd {
  position: relative;
  width: 260px;
  margin-bottom: var(--spacing-10);
}

.LandingPageBlock_headerLogo__Km1vd img {
    position: relative !important;
  }

@media (min-width: 768px) {

.LandingPageBlock_headerLogo__Km1vd img {
      position: absolute;
  }
    }

.LandingPageBlock_hideSectionForMobile___zqHQ {
  display: none;
}

@media (min-width: 1024px) {

.LandingPageBlock_hideSectionForMobile___zqHQ {
    display: block;
}
  }

.LandingPageBlock_hideSectionForTblt__MJNvg {
  display: block;
}

@media (min-width: 1024px) {

.LandingPageBlock_hideSectionForTblt__MJNvg {
    display: none;
}
  }


  :scope .LeasingVehicles_container__0HFjJ h2 {
    margin-bottom: 0;
  }

.LeasingVehicles_cta__MFOSN a {
    color: var(--color-neutral-700);
  }

.LeasingVehicles_vehicle__PjFlO {
  width: 323px;
}

@media (min-width: 768px) {

.LeasingVehicles_vehicle__PjFlO {
    flex-grow: 1;
}

    .LeasingVehicles_vehicle__PjFlO > a {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

.LeasingVehicles_vehicles__nKUxW {
  display: grid;
  padding: 0;
  margin: 0;
  margin-top: var(--spacing-8);
  list-style: none;
  grid-auto-flow: column;
  justify-content: flex-start;
  grid-gap: var(--spacing-2);
  gap: var(--spacing-2);
}

@media (min-width: 768px) {

.LeasingVehicles_vehicles__nKUxW {
    gap: var(--spacing-3);
}
  }

@media (min-width: 1024px) {

.LeasingVehicles_vehicles__nKUxW {
    gap: var(--spacing-4);
}
  }

@media (min-width: 1280px) {

.LeasingVehicles_vehicles__nKUxW {
    gap: var(--spacing-5);
}
  }

.LeasingVehicles_vehicles__nKUxW a {
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
  }

.LeasingVehicles_leasingVehicles__oEJJ_ {
  padding-bottom: var(--spacing-8);
}

@media (min-width: 1280px) {

.LeasingVehicles_leasingVehicles__oEJJ_ {
    padding-bottom: 0;
}
  }

.Newsletter_wrapper__CcDFi {
  background: var(--color-primary-50);
  overflow: hidden;
}
.Newsletter_content__w9y4l {
  gap: var(--spacing-6);
  padding: var(--spacing-10) 0;
}
@media (min-width: 768px) {
.Newsletter_content__w9y4l {
    padding: var(--spacing-12) 0;
}
  }
@media (min-width: 768px) and (orientation: landscape) {
.Newsletter_content__w9y4l {
    align-self: center;
}
  }
@media (min-width: 1024px) {
.Newsletter_content__w9y4l {
    gap: var(--spacing-8);
    padding: var(--spacing-16) 0;
}
  }
@media (min-width: 1280px) {
.Newsletter_content__w9y4l {
    padding-top: calc(var(--spacing-10) * 2);
    padding-bottom: var(--spacing-10);
}
  }
.Newsletter_content__w9y4l a {
    text-decoration: none;
    color: var(--color-mica-blue-500);
  }
.Newsletter_heading__qkhde {
  margin: 0;
}
.Newsletter_subHeading___YkZA {
  margin-bottom: var(--spacing-6);
}
.Newsletter_tcs__1oN2K {
  display: inline;
  padding-top: var(--spacing-3);
}
@media (min-width: 768px) {
.Newsletter_tcs__1oN2K {
    padding-top: var(--spacing-6);
}
  }
.Newsletter_imageContainer__9J1mq {
  flex: 2.5 1 auto;
}
@media (min-width: 768px) {
.Newsletter_imageContainer__9J1mq {
    margin-right: -81px;
}
  }
@media (min-width: 1024px) {
.Newsletter_imageContainer__9J1mq {
    margin-right: 0;
}
  }
.Newsletter_imageContainer__9J1mq img {
    object-fit: contain;
    width: 100%;
    height: auto;
  }

.FaqSectionWrapper_faqSectionWrapper__VmxT7 {
  padding: var(--spacing-10) 0;
}

.ProsAndCons_wrapper__jTcCi {
  margin: 0 auto;
  max-width: 1040px;
}

@media (min-width: 768px) {

.ProsAndCons_wrapper__jTcCi {
    display: grid;
    grid-template-columns: [left] 1fr [right] 1fr;
    grid-column-gap: var(--spacing-6);
    column-gap: var(--spacing-6);
}

    .ProsAndCons_wrapper__jTcCi h2 {
      width: 100%;
      grid-column-start: 1;
      grid-column-end: -1;
    }
  }

.ProsAndCons_prosConsWrapper__1J3hr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-5) 0;
}

.ProsAndCons_half__pjkMU:first-of-type {
    border-bottom: 1px solid var(--neutral-300-disabled, #b5b5b5);
    padding-bottom: var(--spacing-5);
  }

.ProsAndCons_half__pjkMU ul {
    margin: 0;
    padding-left: var(--spacing-5);
  }

.ProsAndCons_half__pjkMU li {
    margin-bottom: var(--spacing-3);
    font-size: 14px;
  }

.ProsAndCons_half__pjkMU[data-has-icon='true'] ul {
      margin: 0;
      list-style: none;
      padding-left: var(--spacing-8);
    }

.ProsAndCons_half__pjkMU[data-has-icon='true'] li {
      position: relative;
      margin-bottom: var(--spacing-3);
    }

.ProsAndCons_half__pjkMU[data-has-icon='true'] li:before {
        content: '';
        position: absolute;
        height: 16px;
        width: 16px;
        background-image: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/red-minus-3x.6254d92b.png);
        background-size: cover;
        left: -30px;
        top: 2px;
      }

.ProsAndCons_half__pjkMU.ProsAndCons_pros__GtXmF li:before {
      background-image: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/green-plus-3x.5fae48b4.png);
    }

.ProsAndCons_half__pjkMU.ProsAndCons_cons__PusfH li:before {
      background-image: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/red-minus-3x.6254d92b.png);
    }

.ProsAndCons_half__pjkMU h3,
  .ProsAndCons_half__pjkMU h6 {
    margin: 0;
    padding: 0;
  }

@media (min-width: 768px) {
    .ProsAndCons_half__pjkMU:first-of-type {
      border-right: 1px solid var(--neutral-300-disabled, #b5b5b5);
      padding-right: var(--spacing-6);
      padding-bottom: 0;
      border-bottom: none;
    }
  }

.ProsAndConsReviewsSection_reviewWrapper__5LhKS {
  padding: 0 0 var(--spacing-10) 0;
}

@media (min-width: 1024px) {

.ProsAndConsReviewsSection_reviewWrapper__5LhKS {
    padding: var(--spacing-6) 0 var(--spacing-6);
}
  }

.ProsAndConsReviewsSection_reviewsRating__H1qTm {
  display: flex;
  gap: var(--spacing-2);
  align-items: center;
}

.ProsAndConsReviewsSection_reviewsRating__H1qTm .ProsAndConsReviewsSection_ratingDivider__KDEWr {
    border-left: 1px solid var(--neutral-300-disabled, #b5b5b5);
    padding-left: var(--spacing-2);
  }

.ProsAndConsReviewsSection_desktopViewRating__VTixv {
  padding-bottom: var(--spacing-5);
  justify-content: center;
}

@media (min-width: 768px) {

.ProsAndConsReviewsSection_desktopViewRating__VTixv {
    justify-content: flex-start;
}
  }

.ProsAndConsReviewsSection_mobileViewRating__RcHgo {
  display: flex;
  align-items: center;
}

.ProsAndConsReviewsSection_title__wutvL {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ProsAndConsReviewsSection_mobileViewTitle__2y7e6 {
  display: flex;
  justify-content: center;
}

.ProsAndConsReviewsSection_prosConsWrapper__q__8x {
  border: 1px solid var(--neutral-300-disabled, #b5b5b5);
  padding: var(--spacing-5);
}

.ProsAndConsReviewsSection_link__WB6ft {
  color: inherit;
}

.ProsAndConsReviewsSection_linkWithRating__Eccgx {
  padding-bottom: var(--spacing-5);
}

.ProsAndConsReviewsSection_mobileViewLink__Nl_f8 {
  display: flex;
  justify-content: center;
  padding: var(--spacing-5) 0;
}

.IntroCarousel_loading__fg9e_ {
  background-color: var(--color-neutral-100);
  height: 180px;
  width: 100%;
}

.IntroCarousel_wrapper__AB22u {
  border: 1px solid var(--color-neutral-100);
  padding: var(--spacing-3);
  overflow: hidden;
  position: relative;
}

@media (max-width: 767px) {
    .IntroCarousel_wrapper__AB22u h1 {
      font-size: 14px;
      margin-top: 0;
      margin-bottom: var(--spacing-3);
    }
  }

@media (min-width: 768px) {

.IntroCarousel_wrapper__AB22u {
    padding: 0 var(--spacing-5) var(--spacing-6);
}

    .IntroCarousel_wrapper__AB22u h1 {
      font-size: 28px;
      line-height: 40px;
    }
  }

.IntroCarousel_wrapper__AB22u .intro-scrollbox {
    gap: var(--spacing-2);
    scroll-snap-type: x mandatory;
    align-items: flex-start;
  }

@media (max-width: 767px) {

.IntroCarousel_wrapper__AB22u .intro-scrollbox {
      width: calc(100vw - 70px);
  }
    }

@media (min-width: 768px) {

.IntroCarousel_wrapper__AB22u .intro-scrollbox {
      width: 100%;
  }
    }

.IntroCarousel_wrapper__AB22u .intro-scrollbox-nav {
    gap: 0;
    display: none;
  }

.IntroCarousel_wrapper__AB22u .intro-scrollbox-nav svg {
      height: var(--spacing-11);
      width: var(--spacing-11);
    }

@media (min-width: 1024px) {

.IntroCarousel_wrapper__AB22u .intro-scrollbox-nav {
      display: flex;
      top: auto;
      bottom: -15px;
      right: -10px;
  }
    }

.IntroCarousel_wrapper__AB22u .intro-scrollbox-dots {
    margin-top: 10px;
  }

@media (max-width: 767px) {

.IntroCarousel_wrapper__AB22u .intro-scrollbox-dots {
      margin-top: 0;
      justify-content: center;
  }
    }

.IntroCarousel_wrapper__AB22u[data-is-opened='false'] {
    height: 42px;
  }

@media (min-width: 768px) {

.IntroCarousel_wrapper__AB22u[data-is-opened='false'] {
      height: 80px;
  }
    }

.IntroCarousel_item__JfnME {
  scroll-snap-align: start;
  padding: 0 0 var(--spacing-2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
          user-select: none;
  text-decoration: none;
  transition: 0.1s;
  gap: 0;
}

@media (max-width: 767px) {

.IntroCarousel_item__JfnME {
    min-width: calc(100vw - 70px);
    padding: 0;
    width: calc(100vw - 70px);
}
  }

@media (min-width: 768px) {

.IntroCarousel_item__JfnME {
    align-items: flex-start;
    justify-content: flex-start;
    flex: 0 0 100%;
}
  }

@media (max-width: 767px) {
    .IntroCarousel_content__1Rk7O p {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: var(--spacing-3);
      margin-top: 0;
    }
  }

@media (min-width: 768px) {

.IntroCarousel_content__1Rk7O {
    width: 85%;
}

    .IntroCarousel_content__1Rk7O p {
      margin-top: 0;
    }
  }

.IntroCarousel_toggle__Z1RLw {
  background-color: var(--color-championship-white);
  border-radius: var(--spacing-1);
  cursor: pointer;
  padding: var(--spacing-1) var(--spacing-2);
  position: absolute;
  top: var(--spacing-1);
  right: var(--spacing-1);
}

@media (max-width: 767px) {
    .IntroCarousel_toggle__Z1RLw span {
      display: none;
    }
  }

@media (min-width: 768px) {

.IntroCarousel_toggle__Z1RLw {
    top: var(--spacing-6);
    right: var(--spacing-5);
}

    .IntroCarousel_toggle__Z1RLw span {
      font-size: 12px;
    }

    .IntroCarousel_toggle__Z1RLw div {
      display: none;
    }
  }

.OfferModal_campaignHeader__0a8_a {
  max-width: 870px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: var(--spacing-6);
  margin-bottom: var(--spacing-5);
}

@media (min-width: 768px) {

.OfferModal_campaignHeader__0a8_a {
    flex-direction: row;
}
  }

@media (min-width: 1024px) {

.OfferModal_modalContainer__klLym {
    height: 635px;
    width: 800px;
    padding-bottom: 14px;
}
  }

.OfferModal_modalBody__vdSsm {
  overflow: auto;
}

.OfferModal_campaignTitle__MUcMA {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  gap: var(--spacing-3);
  padding: var(--spacing-5);
  margin-top: var(--spacing-6);
}

@media (max-width: 767px) {
    .OfferModal_campaignTitle__MUcMA p {
      font-size: 18px;
    }
  }

@media (min-width: 768px) {
    .OfferModal_campaignTitle__MUcMA p {
      font-size: 26px !important;
    }
  }

.OfferModal_campaignDescription__fAmhj {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
  padding: var(--spacing-5) var(--spacing-5) var(--spacing-10) var(--spacing-5);
  min-height: 110px;
}

.OfferModal_campaignSelectorWrapper__uNeH6 {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-2);
  align-items: flex-start;
  padding: var(--spacing-4);
  margin-top: 10px;
  cursor: pointer;
  line-height: 22px;
  background-color: var(--color-sunbeam-blue-50);
}

.OfferModal_campaignSelectorWrapper__uNeH6 svg {
    margin-top: var(--spacing-1);
    font-size: 20px !important;
  }

@media (min-width: 768px) {

.OfferModal_campaignSelectorWrapper__uNeH6 {
    width: 100%;
}
  }

@media (min-width: 1024px) {

.OfferModal_campaignSelectorWrapper__uNeH6 {
    width: 104%;
}
  }

@media (min-width: 1280px) {

.OfferModal_campaignSelectorWrapper__uNeH6 {
    width: auto;
}
  }

.OfferModal_campaignSelectorWrapper__uNeH6 .OfferModal_layoutBody__LixYq .OfferModal_layoutText__sCXk9 {
      display: none;
    }

@media (min-width: 768px) {

.OfferModal_campaignSelectorWrapper__uNeH6 .OfferModal_layoutBody__LixYq .OfferModal_layoutText__sCXk9 {
        display: inline;
        text-decoration: underline;
    }
      }

.OfferModal_campaignSelectorWrapper__uNeH6 .OfferModal_layoutBody__LixYq .OfferModal_layoutTextMobile__Jr8Ux {
      text-decoration: underline;
    }

@media (min-width: 768px) {

.OfferModal_campaignSelectorWrapper__uNeH6 .OfferModal_layoutBody__LixYq .OfferModal_layoutTextMobile__Jr8Ux {
        display: none;
    }
      }

.OfferModal_campaignTitle__MUcMA strong,
.OfferModal_campaignDescription__fAmhj strong {
  background-color: var(--color-primary-500);
  color: #fff;
}

.OfferModal_campaignTitle__MUcMA[data-color='mint'],
.OfferModal_campaignDescription__fAmhj[data-color='mint'] {
  background-color: var(--color-heycar-mint-500);
}

.OfferModal_campaignTitle__MUcMA[data-color='mint_light'],
.OfferModal_campaignDescription__fAmhj[data-color='mint_light'] {
  background-color: var(--color-heycar-mint-200);
}

.OfferModal_campaignTitle__MUcMA[data-color='mint_green'],
.OfferModal_campaignDescription__fAmhj[data-color='mint_green'] {
  background-color: var(--color-heycar-mint-100);
}

.OfferModal_campaignTitle__MUcMA[data-color='mica_blue'],
.OfferModal_campaignDescription__fAmhj[data-color='mica_blue'] {
  background-color: var(--color-sunbeam-blue-600);
}

.OfferModal_campaignTitle__MUcMA[data-color='sunbeam_blue'],
.OfferModal_campaignDescription__fAmhj[data-color='sunbeam_blue'] {
  background-color: var(--color-sunbeam-blue-200);
}

.OfferModal_campaignTitle__MUcMA[data-color='mustang_yellow'],
.OfferModal_campaignDescription__fAmhj[data-color='mustang_yellow'] {
  background-color: var(--color-mustang-yellow-400);
}

.OfferModal_campaignTitle__MUcMA[data-color='light_yellow'],
.OfferModal_campaignDescription__fAmhj[data-color='light_yellow'] {
  background-color: var(--color-mustang-yellow-100);
}

.OfferModal_campaignTitle__MUcMA[data-color='ferrari_red'],
.OfferModal_campaignDescription__fAmhj[data-color='ferrari_red'] {
  background-color: var(--color-old-ferrari-red-500);
}

.OfferModal_campaignTitle__MUcMA[data-color='light_red'],
.OfferModal_campaignDescription__fAmhj[data-color='light_red'] {
  background-color: var(--color-old-ferrari-red-200);
}

.OfferModal_campaignLayoutPadding__6UzE_ {
  display: flex;
  width: 100%;
}

.D2CHowItWorks_wrapper__7cnHM {
  margin: var(--spacing-10) 0;
}

.D2CHowItWorks_wrapper__7cnHM h3 {
    font-size: 24px;
  }

.D2CHowItWorks_list__Yvhb0 {
  display: flex;
  flex-direction: column;
  flex: initial;
  gap: var(--spacing-8);
  margin: 0;
  padding: 0;
}

.D2CHowItWorks_list__Yvhb0 .D2CHowItWorks_item__KCSSz {
    display: flex;
    gap: var(--spacing-3);
    padding: var(--spacing-1) 0;
    align-items: center;
    position: relative;
  }

.D2CHowItWorks_list__Yvhb0 .D2CHowItWorks_item__KCSSz h4 {
      font-size: 16px;
      line-height: 26px;
      margin: 0;
    }

.D2CHowItWorks_list__Yvhb0 .D2CHowItWorks_item__KCSSz p {
      font-size: 14px;
      line-height: 22px;
      margin: 0;
    }

.D2CHowItWorks_list__Yvhb0 .D2CHowItWorks_icon__P536I {
    background-color: var(--color-primary-50);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    width: 44px;
  }

.D2CHowItWorks_list__Yvhb0 .D2CHowItWorks_iconWrapper__uOc1X {
    position: relative;
    height: 100%;
    width: 24px;
  }

.D2CHowItWorks_list__Yvhb0 .D2CHowItWorks_line__MCz1X {
    position: relative;
  }

.D2CHowItWorks_list__Yvhb0 .D2CHowItWorks_line__MCz1X:after {
      background-color: var(--color-primary-50);
      content: '';
      left: calc(50% - 1.5px);
      bottom: -100%;
      text-align: center;
      width: 3px;
      position: absolute;
      height: 100%;
      z-index: 0;
    }

.D2CHowItWorks_list__Yvhb0 .D2CHowItWorks_content__lUHnC {
    flex: 1 1;
  }

.VehicleInfoAndDealerInfoWrapper_carFinanceWidgetMobile__cj_mi {
  margin-bottom: var(--spacing-10);
}

.VehicleInfoAndDealerInfoWrapper_pdpMobileDmpu__Bapqz {
  display: block;
  margin-top: var(--spacing-6);
  background-repeat: repeat;
  background-size: 35%;
}

.VehicleInfoAndDealerInfoWrapper_pdpMobileDmpu__Bapqz.VehicleInfoAndDealerInfoWrapper_pdpMobileDmpu__Bapqz [class^='Ad_wrapper'] {
    margin-top: 0;
  }

@media (min-width: 1024px) {

.VehicleInfoAndDealerInfoWrapper_pdpMobileDmpu__Bapqz {
    background: none;
    display: none;
}
  }

.HeroImage_wrpr__3n89Z {
  position: relative;
  margin: 0;
  width: 100%;
  aspect-ratio: 4/3;
  /* aspect-ratio: 3 / 2.39; */
  overflow: hidden;
}

.HeroImage_img__QWc1P {
  object-fit: cover;
  object-position: center;
}

.HeroImage_racBadge__TN9os {
  position: absolute;
  bottom: var(--spacing-2);
  left: var(--spacing-2);
  z-index: 1;
  display: flex;
}

.HeroImage_racBadge__TN9os > div {
    border-radius: var(--spacing-1);
    line-height: 20px;
  }

.HeroImage_imgsNum__QqYBZ {
  position: absolute;
  bottom: var(--spacing-2);
  right: var(--spacing-2);
  z-index: 1;
  display: flex;
  align-items: center;
  padding: var(--spacing-2) 6px;
  height: 32px;
  border-radius: var(--spacing-1);
  background-color: rgba(29, 29, 29, 0.8);
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.HeroImage_imgsNum__QqYBZ svg {
    margin-right: var(--spacing-1);
  }

.ExpandableFinance_expandable__EH1ol {
  height: 48px;
  z-index: 6;
  position: absolute;
  bottom: 50px;
  height: max-content;
  width: 100%;
  background-color: white;
  padding: 0 var(--spacing-4);
  border-right: 1px solid var(--color-tarmac-grey-100);
  border-left: 1px solid var(--color-tarmac-grey-100);
}

.ExpandableFinance_expandable__EH1ol .ExpandableFinance_financePrice__My90A {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    margin: var(--spacing-3) 0;
    color: var(--neutral-700);
    color: var(--color-tarmac-grey-700);
  }

.ExpandableFinance_expandable__EH1ol .ExpandableFinance_financeCopy__EEHhC {
    width: 100%;
    margin: 0;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-tarmac-grey-700);
    padding-bottom: var(--spacing-3);
  }

.ExpandableFinance_expandable__EH1ol .ExpandableFinance_financeCopy__EEHhC > * {
      font-size: 12px !important;
    }

.ExpandableFinance_readMe__fiGMO {
  cursor: pointer;
  font-size: 10px;
  font-weight: 500;
  line-height: var(--spacing-4);
  position: absolute;
  right: var(--spacing-1);
  bottom: var(--spacing-2);
  color: var(--color-tarmac-grey-700);
  text-underline-offset: 1px;
  text-decoration-thickness: 1px;
  -webkit-text-decoration: underline var(--color-tarmac-grey-700);
          text-decoration: underline var(--color-tarmac-grey-700);
}

.ExpandableFinance_finance__Ix0Hd {
  position: absolute;
  margin: 0;
  bottom: var(--spacing-2);
  left: var(--spacing-3);
  right: 70px;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  color: var(--neutral-600);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ExpandableFinance_finance__Ix0Hd:active {
    background-color: var(--color-championship-white);
  }

.ExpandableFinance_finance__Ix0Hd > * {
    font-size: 12px !important;
  }

.ExpandableFinance_noFinance__7hghR {
  font-size: 10px;
  font-weight: 400;
  line-height: var(--spacing-4);
  padding: 0 var(--spacing-3);
  margin-top: var(--spacing-2);
}

.PdpWrapper_pdpWrapper__m_rwF {
  display: flex;
  flex-direction: column;
  gap: 0 var(--spacing-8);
}
@media (min-width: 768px) {
.PdpWrapper_pdpWrapper__m_rwF {
    flex-direction: row;
    margin-top: var(--spacing-4);
}

    .PdpWrapper_pdpWrapper__m_rwF .PdpWrapper_mainCol__zLCbR {
      min-width: 60%;
    }
  }
[data-service-consumer='native'] .PdpWrapper_mainPdpWrapper__By_J_ {
    margin-bottom: var(--spacing-16);
  }
.PdpWrapper_container__OJZ8T {
  display: flex;
  flex-direction: column;
  gap: 0 var(--spacing-10);
  margin-bottom: var(--spacing-10);
  align-items: space-between;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  width: 100%;
}
@media (min-width: 1024px) {
.PdpWrapper_container__OJZ8T {
    flex-direction: row;
    padding-top: var(--spacing-8);
}
  }
@media (min-width: 1440px) {
.PdpWrapper_container__OJZ8T {
    padding-top: var(--spacing-16);
}
  }
.PdpWrapper_container__OJZ8T [class^='UspsItems_uspsPadding'] {
    order: 2;
    background-color: white;
    padding-top: var(--spacing-4);
  }
@media (min-width: 1024px) {
.PdpWrapper_container__OJZ8T [class^='UspsItems_uspsPadding'] {
      order: initial;
      position: relative;
      z-index: 1;
      margin-bottom: -20px;
  }
    }
@media (min-width: 1024px) {
.PdpWrapper_container__OJZ8T [class^='UspsItems_uspsPadding'][data-scrolled='true'] {
        top: 70px;
        margin-bottom: 50px;
    }
      }
@media (min-width: 1440px) {
.PdpWrapper_container__OJZ8T [class^='UspsItems_uspsPadding'][data-scrolled='true'] {
        top: 60px;
        margin-bottom: 40px;
    }
      }
.PdpWrapper_leftCol__xo9lq {
  min-width: 0;
  display: contents;
  flex-direction: column;
  flex: 0 1 868px;
}
@media (min-width: 1024px) {
.PdpWrapper_leftCol__xo9lq {
    display: flex;
}
  }
.PdpWrapper_rightCol__msTTW {
  min-width: 0;
  display: contents;
  flex-direction: column;
  flex: 0 0 300px;
}
@media (min-width: 1024px) {
.PdpWrapper_rightCol__msTTW {
    display: flex;
}
  }
.PdpWrapper_galleryContainer__2p3yE {
  order: 1;
  position: relative;
  margin-left: calc(var(--spacing-5) * -1);
  width: 100vw;

  margin-bottom: 16px;
}
@media (min-width: 768px) {
.PdpWrapper_galleryContainer__2p3yE {
    margin-left: calc(var(--spacing-6) * -1);
}
  }
@media (min-width: 1024px) {
.PdpWrapper_galleryContainer__2p3yE {
    width: auto;
    width: initial;
    margin-left: 0;
    margin-left: initial;
}
  }
.PdpWrapper_vehicleInfoContainer__1vmJF {
  order: 2;
  flex-direction: column;
  display: flex;
}
@media (min-width: 1024px) {
.PdpWrapper_vehicleInfoContainer__1vmJF {
    flex-wrap: wrap;
    order: initial;
    height: 100%;
    background: var(--color-championship-white);
    z-index: 8;
    padding-left: var(--spacing-3);
}
  }
.PdpWrapper_vehicleDetailsContainer__2rhu0 {
  order: 3;
}
.PdpWrapper_sideCol__bDvdf {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.PdpWrapper_image__1Vp05 {
  width: 100%;
  aspect-ratio: 4/3;
  object-fit: contain;
  object-position: center;
}
.PdpWrapper_buyOnlineCTA__PsIJ0 {
  width: 100%;
  margin-bottom: 10px;
}
.PdpWrapper_pdpExternalAdsPadding__fZWD_ {
  padding-top: var(--spacing-3);
}
.PdpWrapper_pdpExternalSidebarAds__VpgVQ {
  display: none;
  padding-top: var(--spacing-3);
}
@media (min-width: 1024px) {
.PdpWrapper_pdpExternalSidebarAds__VpgVQ {
    display: block;
}
  }

.TooltipFinance_tooltipWrapper__WUq5w {
  display: inline-flex;
}
.TooltipFinance_expandable__7I7Zz {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  background-color: white;
  width: 100%;
  z-index: 6;
  padding: var(--spacing-4);
  transition: all 0.3s, transform 0.3s;
  border: 1px solid var(--color-tarmac-grey-100);
  bottom: -100%;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: default;
}
.TooltipFinance_expandable__7I7Zz.TooltipFinance_open___OCZq {
    height: 100%;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
  }
.TooltipFinance_expandable__7I7Zz .TooltipFinance_fullLegalText__sEhi8 {
    font-size: var(--spacing-3);
    font-weight: 400;
    overflow-y: scroll;
  }
.TooltipFinance_expandable__7I7Zz .TooltipFinance_header__a6CEE {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
.TooltipFinance_expandable__7I7Zz .TooltipFinance_closeButton__5nS6r {
    cursor: pointer;
  }
.TooltipFinance_expandable__7I7Zz .TooltipFinance_closeButton__5nS6r svg {
      fill: var(--color-neutral-700);
    }
:scope .TooltipFinance_toggle__5ed_V {
  cursor: pointer;
}
:scope .TooltipFinance_toggle__5ed_V svg {
    transform: translateY(var(--spacing-2));
  }

.TrustpilotMicroCombo_mainWrapper__dMAvb {
  /* border-top: 1px solid var(--color-neutral-300); */
  margin-top: 10px;
}

.TrustpilotMicroCombo_trustpilotWrapper__uNQMR {
  display: flex;
  flex-direction: column;
  color: inherit;
  text-decoration: none;
  gap: 8px;
  padding-top: 20px;
}

@media (min-width: 768px) and (max-width: 1023px) {

.TrustpilotMicroCombo_trustpilotWrapper__uNQMR {
    flex-direction: row;
}
  }

.TrustpilotMicroCombo_ratingWrapper__pM2kq {
  display: flex;
  align-items: center;
  color: var(--neutral-700-pressed-default-text);
}

.TrustpilotMicroCombo_ratingWrapper__pM2kq h6 {
    margin: 0;
    padding: 0;
  }

.TrustpilotMicroCombo_imageWrapper__7MyCA {
  display: flex;
  align-items: center;
}

.TrustpilotMicroCombo_trustpilotImage__3rwSi {
  margin-left: var(--spacing-4);
  margin-right: var(--spacing-1);
}

@media (min-width: 768px) {

:scope .TrustpilotMicroCombo_ratingWrapper__pM2kq:first-child {
    margin-right: var(--spacing-1);
}
  }


  .FaqBlockWrapper_wrapper__rFrmB > div {
    display: block;
  }

.BoxOutTextOnly_wrapper__sYBuZ {
  padding: var(--spacing-10);
}.BoxOutTextOnly_wrapper__sYBuZ h2 {
    margin-top: 0;
  }.BoxOutTextOnly_wrapper__sYBuZ p {
    margin-bottom: var(--spacing-4);
  }

