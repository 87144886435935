.modalContainer {
  @media (--tablet-l) {
    height: 635px;
    width: 800px;
  }
}

.modalBody {
  overflow: auto;
}
