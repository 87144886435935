.container {
  padding: 14px;
  background-color: #f1f4fa;
  list-style: none;
}

.summary {
  list-style: none;
  padding: 0;
  margin: 0;

  > li {
    border-bottom: 1px #b5b5b5;
    &:first-child {
      border-bottom-style: dashed;
      padding: 8px 0;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  & li.instalments {
    margin: 0;
    display: flex;
    font-weight: 700;
    font-size: 16px;

    & span:first-child {
      flex-basis: 70%;
    }
    & span:last-child {
      text-align: right;
      flex-basis: 70%;
    }
  }
  & li > span > .monthlyRate {
    min-width: 188px;
    white-space: wrap;
    font-size: 12px;
  }

  & li.additionalServices {
    & span:first-child {
      flex-basis: 60%;
    }
  }

  & li > ul {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
    & li {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      & span:first-child {
        flex-basis: 55%;
      }
      & span:last-child {
        font-weight: 500;
        text-align: right;
      }
    }
  }
}

.appFeeContent {
  margin-left: 14px;
  white-space: nowrap;
}

.infoIcon {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  padding: 0 var(--spacing-1) 0 var(--spacing-1);
}

.divider {
  border-bottom: 1px solid var(--neutral-300, #b5b5b5);
  margin: var(--spacing-4) 0 var(--spacing-4) !important;
}
